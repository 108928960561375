import React from "react";
import classes from "./NotFoundPage.module.scss";

const NotFoundPage = () => {
  return (
    <div className={classes.container}>
      <div>
        <h2>Oops!</h2>
        <div className={classes.line}></div>
        <p>The page you're looking for is not here!</p>
        <p>Please try another page.</p>
        <p>Thank you!</p>
      </div>
    </div>
  );
};

export default NotFoundPage;
