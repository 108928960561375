import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import styles from "./SearchPage.module.scss";

import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";

import IconButton from "@material-ui/core/IconButton";
import { fade, makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";

import { getSearchResults } from "../actions/search";
import { showLoader, hideLoader } from "../actions/auth";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },

  name: {
    fontSize: "12px",
    marginRight: "10px",
  },

  edit: {
    marginRight: theme.spacing(0.2),
    color: "black",
  },

  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    padding: "0 !important",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    // padding: theme.spacing(0, 2),
    // height: "100%",
    position: "absolute",
    padding: "0px !important",
    zIndex: "100",
    right: "60px",
    top: "45px",

    // pointerEvents: "none",
    // display: "flex",
    // alignItems: "center",
    justifyContent: "center",
    color: "black !important",
    [theme.breakpoints.up("sm")]: {
      transform: "scale(1.5)",
    },
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
    paddingLeft: "10px !important",
    [theme.breakpoints.up("sm")]: {
      paddingLeft: "30px !important",
    },
  },
  inputInput: {
    border: "solid grey 1px",
    borderRadius: "10px",
    width: "100%",
    padding: "15px 15px 15px 10px",
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + 10px)`,
    transition: theme.transitions.create("width"),
  },
}));

const SearchPage = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [query, setQuery] = useState("");

  let array = useSelector((state) => state.searchResults);
  // console.table(array);

  let userList = [];

  array.forEach((item) => {
    if (item.user) {
      // console.log(item.user.first_name);
      let obj = {};
      obj["pk"] = item.pk;
      obj["first_name"] = item.user.first_name;
      obj["last_name"] = item.user.last_name;
      obj["isPrimary"] = true;
      obj["bday"] = item.bday;
      userList.push(obj);
      // console.table(userList);
    } else {
      userList.push(item);
    }
  });

  function compare(a, b) {
    if (a.last_name < b.last_name) {
      return -1;
    }
    if (a.last_name > b.last_name) {
      return 1;
    }
    return 0;
  }

  userList.sort(compare);

  const handleSubmit = (e) => {
    if (query.length > 0) {
      e.preventDefault();
      dispatch(getSearchResults(query));
    } else {
      // console.log("cant be empty");
    }
  };

  const keyPress = (e) => {
    if (e.keyCode == 13 && query.length > 0) {
      e.preventDefault();
      dispatch(getSearchResults(query));
    } else {
      // console.log("cant be empty");
    }
  };

  useEffect(() => {
    setTimeout(function () {
      dispatch(hideLoader());
    }, 1000);
  }, []);

  const searchSection = () => {
    return (
      <div className={classes.search}>
        <IconButton
          className={classes.searchIcon}
          edge='end'
          aria-label='answer questions'
          aria-controls='go-to-search-page'
          aria-haspopup='true'
          onClick={handleSubmit}
          color='inherit'
        >
          <SearchIcon />
        </IconButton>

        <InputBase
          placeholder='Search database…'
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          inputProps={{ "aria-label": "search" }}
          onChange={(e) => setQuery(e.target.value)}
          onKeyDown={keyPress}
        />
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <div>
        <h2>Search</h2>
        {searchSection()}
        <div className={styles.line}></div>
        {userList.map((user, index) => {
          if (user.isPrimary) {
            return (
              <Link
                key={index}
                to={{
                  pathname: `/profile/${user.pk}`,
                  state: { id: user.pk },
                }}
              >
                <p
                  style={{
                    fontSize: "18px",
                    textDecoration: "none !important",
                  }}
                >
                  {user.last_name}, {user.first_name}{" "}
                  {user.bday ? (
                    <span>(born {user.bday.substring(0, 4)})</span>
                  ) : null}
                </p>
              </Link>
            );
          } else {
            // console.log("THIS IS THE SECCC USER");
            // console.table(user);
            return (
              <Link
                key={index}
                to={{
                  pathname: `/secondary/${user.pk}`,
                  state: { id: user.pk },
                }}
              >
                <p
                  style={{
                    fontSize: "18px",
                    textDecoration: "none !important",
                  }}
                >
                  {user.last_name}, {user.first_name}{" "}
                  {user.bday ? (
                    <span>(born {user.bday.substring(0, 4)})</span>
                  ) : null}
                </p>
              </Link>
            );
          }
        })}
      </div>
    </div>
  );
};

export default SearchPage;
