import React, { useState } from "react";
import { useSelector } from "react-redux";
import { store } from "../../index";

import CircularProgress from "@material-ui/core/CircularProgress";

import styles from "./AccessQA.module.scss";

const AccessQA = ({ text, match, id, userID }) => {
  const loading = useSelector((state) => state.auth.compLoad);

  //   useEffect(() => {
  //     dispatch(getUser(auth.id));
  //   }, [dispatch, isSubmitted, open, changeRender, auth.id]);

  if (!loading) {
    if (window.location.href.indexOf("secondary") > -1) {
      return (
        <div className={styles.containerQuestions}>
          <div className={styles.padding}>
            <h3>{store.getState().secondaryUser.questions[match].question}</h3>
            <h2>{text}</h2>
          </div>
        </div>
      );
    } else {
      return (
        <div className={styles.containerQuestions}>
          <div className={styles.padding}>
            <h3>{store.getState().user.questions[match].question}</h3>
            <h2>{text}</h2>
          </div>
        </div>
      );
    }
  } else {
    return (
      <CircularProgress
        style={{
          position: "fixed",
          right: "50%",
          top: "50%",
        }}
      ></CircularProgress>
    );
  }
};

export default AccessQA;
