export default (
  user = {
    id: "",
    first_name: "",
    last_name: "",
    bio: "",
    questions: null,
    answers: null,
    secondary_users: [],
    bday: "",
  },
  action
) => {
  switch (action.type) {
    // We get a response back from the backend, has data in payload.. what do we do with the data?
    case "CREATE_USER":
      // console.log("Response:" + action.payload.response);
      // console.log("auth token before assignment: " + store.auth.token);
      // store.auth.token = action.payload.token;
      // store.getState().auth.
      // console.log("auth token AFTER assignment: " + store.auth.token);
      // console.table(action.payload);
      return {
        ...user,
        id: action.payload.id,
        email: action.payload.email,
        first_name: action.payload.first_name,
        last_name: action.payload.last_name,
        bday: action.payload.bday,
      };

    case "ADD_SECONDARY_USER":
      return {
        ...user,
        secondary_users: [
          ...user.secondary_users,
          {
            id: action.payload.id,
            first_name: action.payload.first_name,
            last_name: action.payload.last_name,
            public: action.payload.public,
            biography: action.payload.biography,
            user_profile: action.payload.user_profile,
            bday: action.payload.bday,
          },
        ],
      };

    case "GET_USER":
      return {
        id: action.payload.id,
        email: action.payload.email,
        first_name: action.payload.first_name,
        last_name: action.payload.last_name,
        bio: action.payload.bio,
        questions: action.payload.questions,
        answers: action.payload.answers,
        public: action.payload.public,
        secondary_users: action.payload.secondary_users,
        bday: action.payload.bday,
      };
    case "GET_ACCOUNT":
      // console.log("THIS IS THE GET ACCOUNT payload:");
      // console.table(action.payload);
      return {
        ...user,
        public: action.payload.public,
        id: action.payload.id,
        email: action.payload.email,
        first_name: action.payload.first_name,
        last_name: action.payload.last_name,
        bio: action.payload.bio,
      };

    case "UPDATE_NAME":
      return {
        ...user,
        first_name: action.payload.first_name,
        last_name: action.payload.last_name,
      };

    case "UPDATE_EMAIL":
      return {
        ...user,
        email: action.payload.email,
      };

    case "UPDATE_ANSWER":
    case "DELETE_ANSWER":
      // console.table(action.payload.answer);
      return {
        ...user,
        answers: action.payload.answer,
      };

    case "UPDATE_BIO":
      // console.log("UPDATE BIO PAYLOAD: " + action.payload);
      return {
        ...user,
        bio: action.payload.bio,
      };

    case "UPDATE_BDAY":
      console.log("UPDATE BDAY PAYLOAD: " + action.payload);
      return {
        ...user,
        bday: action.payload.bday,
      };

    default:
      return user;
  }
};
