import React, { useEffect, useState } from "react";
import { useParams, Redirect, useLocation, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import classes from "./VerificationPage.module.scss";

import { getVerification } from "../actions/auth";

const VerificationPage = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  let { id } = useParams();

  useEffect(() => {
    dispatch(getVerification(id));
  }, []);

  if (auth.verificationResponse == "Account has been activated") {
    return (
      <div className={classes.container}>
        <div>
          <h2>Verification Success!</h2>
          <div className={classes.line}></div>
          <p>You may now sign in.</p>
        </div>
      </div>
    );
  } else {
    return (
      <div className={classes.container}>
        <div>
          <h2>Verification Failed</h2>
          <div className={classes.line}></div>
          <p>
            Please try clicking the verification link again. If you are unable
            to sign in, please contact us.
          </p>
        </div>
      </div>
    );
  }
};

export default VerificationPage;
