import React from "react";
import classes from "./ContactPage.module.scss";

const ContactPage = () => {
  return (
    <div className={classes.container}>
      <div>
        <h2>Contact</h2>
        <div className={classes.line}></div>
        <p>
          For all inquiries, please contact us at{" "}
          <a href='mailto: info@everypast.com'>info@everypast.com</a>.
        </p>
        <p>Thank you!</p>
      </div>
    </div>
  );
};

export default ContactPage;
