import React from "react";
import classes from "./PrivacyPolicyPage.module.scss";

window.onbeforeunload = function () {
  window.scrollTo(0, 0);
};

const PrivacyPolicyPage = () => {
  return (
    <div className={classes.container}>
      <div>
        <h2>EveryPast Privacy Policy</h2>
        <div className={classes.line}></div>
        <p>
          <strong>Effective Date:</strong> 4 August 2021
        </p>
        <p>
          At EveryPast, your privacy is a top priority. EveryPast is committed
          to being a good steward of your Personal Information (defined{" "}
          <a href='#intro'>below</a>), handling it in a responsible manner, and
          securing it with administrative, technical, organizational and
          physical safeguards.
        </p>
        <p>
          We believe in being honest, direct and transparent when it comes to
          your data. EveryPast follows three guiding principles when it comes to
          your privacy:
        </p>
        <li>
          <strong>Transparency.</strong> We work hard to be transparent about
          what Personal Information we collect and process.
        </li>
        <li>
          <strong>Simplicity.</strong> We try to use easy-to-understand language
          to describe our privacy practices to help you make informed choices.
        </li>
        <li>
          <strong>Control.</strong> We give you control over the Personal
          Information you provide to us, and how it is used, shared, and
          retained.
        </li>
        <h3>
          Other Important Things for You to Understand When You Use Our Services
        </h3>
        <p>
          When you store your life story with us, you should feel confident and
          informed about how we use your Personal Information. Our full Privacy
          Statement is below and we encourage you to read it. It covers all
          EveryPast websites, services, and mobile apps that link to this
          Privacy Statement.
        </p>
        <p>
          <strong>California Customers Only</strong> - The{" "}
          <strong>California Resident Additional Privacy Statement</strong>{" "}
          <a href='#california'>below</a> contains additional details for
          California residents required by the CCPA.
        </p>
        <h3>Table of Contents</h3>
        1. <a href='#intro'>Introduction</a>
        <br />
        2.{" "}
        <a href='#creation'>
          Account Creation and Your Engagement with EveryPast Services
        </a>
        <br />
        3.{" "}
        <a href='#personalinfo'>
          What Personal Information Does EveryPast Collect from You?
        </a>
        <br />
        4.{" "}
        <a href='#collectuse'>
          What Information Does EveryPast Collect Through Your Use of the
          Services?
        </a>
        <br />
        5. <a href='#othersources'>Information We Collect from Other Sources</a>
        <br />
        6.{" "}
        <a href='#howuse'>How Does EveryPast Use Your Personal Information?</a>
        <br />
        7.{" "}
        <a href='#sharing'>
          What Information Do We Share, When Do We Share It, and Who Are the
          Recipients?
        </a>
        <br />
        8.{" "}
        <a href='#rightsandchoices'>
          Your Rights and Choices Regarding Your Personal Information
        </a>
        <br />
        9. <a href='#retention'>What Are Our Retention Practices?</a>
        <br />
        10. <a href='#delete'>How Can I Delete My Personal Information?</a>
        <br />
        11. <a href='#security'>Security</a>
        <br />
        12. <a href='#transfers'>International Data Transfers</a>
        <br />
        13. <a href='#changes'>Changes to This Statement</a>
        <br />
        14.{" "}
        <a href='#legalbases'>
          Legal Bases Under EU General Data Protection Regulation for Processing
          Personal Information of EU Residents
        </a>
        <br />
        15.{" "}
        <a href='#identitycontact'>
          Identity and Contact Details of the Data Controller
        </a>
        <br />
        16.{" "}
        <a href='#california'>
          California Resident Additional Privacy Statement (California customers
          only)
        </a>
        <a name='intro'></a>
        <h3>1. Introduction</h3>
        <p>
          At EveryPast, we help you recall and preserve your life story and the
          life stories of your loved ones. To provide and improve the websites,
          mobile applications, and services (collectively the “
          <strong>Services</strong>”), we collect, process, and store "
          <strong>Personal Information</strong>," which is information that can
          identify you directly or indirectly, as further described in Section
          3, such as your name, email or address, or information that could
          reasonably be linked back to you. This Privacy Statement describes our
          practices for collecting, storing and processing your Personal
          Information and the controls we provide you to manage it within our
          Services. In addition, our <a href='/cookiepolicy'>Cookie Policy</a>{" "}
          describes our use of browser cookies and similar tracking
          technologies.
        </p>
        <p>
          <strong>Please note</strong>: Information about deceased persons is
          not Personal Information under this Privacy Statement.
        </p>
        <a name='creation'></a>
        <h3>2. Account Creation and Your Engagement with EveryPast Services</h3>
        <p>
          The Personal Information contractually required to create an account
          with EveryPast is limited to your name, an email address, and a
          password. For paid users only, billing information is also required
          for account creation.
        </p>
        <p>
          By creating an account, you are telling us that you understand
          EveryPast will collect, process, and share your Personal Information
          as described in this Privacy Statement.
        </p>
        <p>
          At any time, you can request that EveryPast delete Personal
          Information you have uploaded into your account. You can also delete
          your entire account. Please see <a href='#delete'>Section 10</a> for
          specific details about deleting your Personal Information.
        </p>
        <a name='personalinfo'></a>
        <h3>3. What Personal Information Does EveryPast Collect From You?</h3>
        <h4>Account Information</h4>
        <li>Your name (required).</li>
        <li>An email address (required).</li>
        <li>A password that you create (required).</li>
        <li>Billing information.</li>
        <li>
          Certain EveryPast brands may support third-party sign-in services such
          as Facebook, Google and Apple that provide EveryPast your Personal
          Information. Please refer to our{" "}
          <a href='/cookiepolicy'>Cookie Policy</a> to learn about how third
          parties use technology for analytics and targeting purposes and about
          the controls we provide you.
        </li>
        <h4>Credit Card/Payment Information</h4>
        <li>
          Payment information, such as your credit card number, and your billing
          and shipping address(es), when you make a purchase.
        </li>
        <h4>Profile Information</h4>
        <p>
          We collect the information that you provide when you create a user
          profile (for example, your name and your email address) and
          information about you from your biographical answers and submissions.
          Your EveryPast user profile information is visible to other Users
          (defined in the EveryPast <a href='/terms'>Terms and Conditions</a>)
          by default, and you can make this information private by changing the
          privacy setting in your My Account page.
        </p>
        <h4>User Provided Content</h4>
        <p>
          We collect information when you voluntarily contribute to the
          Services, for example, content you upload or biographical submissions
          and answers, and information you voluntarily provide in response to
          our email surveys or questionnaires available through the Services.
          This may include health or medical information.
        </p>
        <h4>Note About Health-Related Information</h4>
        <p>
          EveryPast is not a covered entity under the Health Insurance
          Portability and Accountability Act (“<strong>HIPAA</strong>”), and as
          a result no User Provided Content provided by you is subject to or
          protected by HIPAA.
        </p>
        <h4>Your Communications</h4>
        <p>
          We host and maintain your communications with other Users through the
          communications features on our platform, and also collect your
          information when you communicate with EveryPast Member Services and
          support teams for our other Services, including audio and visual
          information (such as recordings of calls with EveryPast Member
          Services or information voluntarily shared when participating in
          consumer insights research).
        </p>
        <h4>Contests and Promotions</h4>
        <p>
          We collect Personal Information when you voluntarily participate in
          contests and special promotions we run or sponsor.
        </p>
        <h4>Other Protected Classifications and Sensitive Data</h4>
        <p>
          We may collect information related to certain protected
          classifications such as gender or marital status.
        </p>
        <a name='collectuse'></a>
        <h3>
          4. What Information Does EveryPast Collect Through Your Use of the
          Services?
        </h3>
        <h4>Computer and Mobile Device Information</h4>
        <p>
          EveryPast collects information about how you access our Services,
          including the website you visited before and after EveryPast’s site.
        </p>
        <p>
          We also collect the IP address where your computer, mobile device, or
          the proxy server that you use accesses the Internet, in addition to
          other technical information, such as:
        </p>
        <li>Your computer operating system;</li>
        <li>Your web browser;</li>
        <li>
          Your mobile device identifier provided by your mobile device operating
          system and your mobile operating system; and,
        </li>
        <li>The name of your internet service provider or mobile carrier.</li>
        <p>
          EveryPast may collect geolocation information from your device with
          your permission.
        </p>
        <h4>Information from Cookies and Similar Technologies</h4>
        <p>
          EveryPast uses cookies and similar technologies as well as technical
          integrations with marketing and advertising partners as described in
          our <a href='/cookiepolicy'>Cookie Policy</a>. Please refer to our{" "}
          <a href='/cookiepolicy'>Cookie Policy</a> to learn about our practices
          and the controls we provide you.
        </p>
        <h4>Information Shared Through Social Media Features</h4>
        <p>
          If you interact with social media through the Services, for example
          “Share,” “Post,” “Tweet,” “Pin,” or “Follow Us” links to sites such as
          Facebook, Twitter, Pinterest, Instagram, and YouTube, EveryPast will
          collect these interactions and whatever account information these
          services make available to us dependent on your privacy settings with
          that third party.
        </p>
        <p>
          Your interactions with these features are governed by the privacy
          statement of the applicable third-party company.
        </p>
        <h4>Information from Your Use of the Services</h4>
        <p>
          We collect information about your use of the Services, such as when
          you search or access other users’ public profiles, which pages you
          view, links you click, or when you add answers to your profile, etc.,
          which we may use to infer details about you as a customer and your
          interests to provide a better experience through, for example, search
          suggestions.
        </p>
        <a name='othersources'></a>
        <h3>5. Information We Collect from Other Sources</h3>
        <h4>Information from Public and Historical Records</h4>
        <p>
          EveryPast collects records from various sources, usually from official
          record sources, including newspapers, historical books, and
          biographical books, which may contain Personal Information relating to
          you or your immediate family members. Public and historical records
          will also contain Personal Information relating to individuals who are
          not EveryPast Users. These records are usually made available to Users
          as part of the EveryPast subscription Services.
        </p>
        <h4>Information from Third Parties</h4>
        <p>
          We may also receive information about you from third parties. For
          example, we may supplement the data we collect with information
          licensed from third parties in order to personalize the Services and
          our offers to you.
        </p>
        <p>
          If you purchase a gift subscription, we will collect Personal
          Information, such as the recipient’s name, shipping address, email
          address, and the necessary purchase information to complete the gift
          and notify the recipient.
        </p>
        <a name='howuse'></a>
        <h3>6. How Does EveryPast Use Your Personal Information?</h3>
        <h4>Personal Information (Generally)</h4>
        <p>
          We use your Personal Information to provide, personalize, improve,
          update and expand our Services. This includes:
        </p>
        <li>
          Authenticating your access to the Services and improving EveryPast
          information security;
        </li>
        <li>
          Processing your payments for subscriptions and other products and
          features;
        </li>
        <li>Building new and improving existing Services;</li>
        <li>
          Helping you create, and providing insights about, your life stories
          based on data in EveryPast’s databases;
        </li>
        <li>
          Issuing surveys and questionnaires for use in the Services, as well as
          facilitating product development and research initiatives;
        </li>
        <li>Conducting scientific, statistical, and historical research;</li>
        <li>
          Detecting and protecting against error, fraud, or other criminal or
          malicious activity; and,
        </li>
        <li>
          Enforcing our <a href='/terms'>Terms and Conditions</a>.
        </li>
        <h4>Communications</h4>
        <p>
          We use your Personal Information to communicate with you about the
          Services, such as when we:
        </p>
        <li>Respond to your inquiries to Member Services;</li>
        <li>
          Alert you to records pertaining to people to whom you may be related;
        </li>
        <li>Inform you of changes to Services or new Services;</li>
        <li>
          Ask you to participate in EveryPast media productions, advertisements,
          conducting consumer insights research, or testimonials; and,
        </li>
        <li>
          Provide you with information or request action in response to
          technical, security, and other operational issues.
        </li>
        <h4>Market Products and Offers from Us or Our Business Partners</h4>
        <p>
          We may use some Personal Information (for example, demographic
          information available from third-party sources or from parts of
          EveryPast [profile, etc.]) to market our products and offers from us
          or our business partners. This marketing includes advertising based on
          your interests.
        </p>
        <p>
          For any consent-based marketing we do, you can control how we market
          to you by using the unsubscribe link in any marketing email you
          receive, or by following the instructions in marketing communications
          you receive. For more information, please see our{" "}
          <a href='/cookiepolicy'>Cookie Policy</a>. For certain personalized
          advertising on third-party platforms, we rely on our own legitimate
          interests as the legal basis (please see{" "}
          <a href='#sharing'>Section 7</a>) and you should control your
          marketing preferences directly on those platforms.
        </p>
        <a name='sharing'></a>
        <h3>
          7. What Information Do We Share, When Do We Share It and Who Are the
          Recipients?
        </h3>
        <p>
          EveryPast does not share your individual Personal Information with
          third-parties except as described in this Privacy Statement or with
          your additional consent. We do not voluntarily share your information
          with law enforcement. Also, as previously explained, we will not share
          your Genetic Information with insurance companies, employers, or
          third-party marketers without your express consent.
        </p>
        <p>
          <strong>Please note</strong>: EveryPast does not sell your Personal
          Information.
        </p>
        <p>
          The circumstances described below explain when sharing might occur:
        </p>
        <h4>EveryPast Companies</h4>
        <p>
          We may share your information within the EveryPast group of companies
          in order to provide our Services and to improve our Services.
        </p>
        <h4>Other Users or Others You May Choose to Share With</h4>
        <p>
          As part of your use of the Services, you have the option to add or
          share information with all Users of the Service or through sharing
          features with individual Users and individuals who are not EveryPast
          users.
        </p>
        <p>
          Information in your public profile(s) will be seen by all other Users
          and visitors to the website.
        </p>
        <p>
          If you share details of your life story or Personal Information
          outside the Services, you do so at your own risk.
        </p>
        <h4>Service Providers</h4>
        <p>
          We work with other companies when providing and marketing the
          Services. As a result, these companies will have access to or have
          some of your information, including some of your Personal Information
          in their systems. These companies are subject to contractual
          obligations governing privacy, data security and confidentiality
          consistent with applicable laws.
        </p>
        <p>These companies include our:</p>
        <li>Payment processors (such as Payment Information);</li>
        <li>
          Cloud services infrastructure providers (such as Account Information);
        </li>
        <li>
          Vendors that assist us in marketing and consumer research analytics,
          fraud prevention, and security (such as email address);
        </li>
        <li>
          Communications infrastructure providers (such as name and email
          address); and,
        </li>
        <li>
          Vendors that help us provide some Member Services functions, like
          phone support or survey tools (such as Account Information or name or
          email address)
        </li>
        <h4>Analytics and Advertising Partners</h4>
        <p>
          We work with third-party partners for analytics and advertising
          purposes. By collecting certain information, it helps us to better
          personalize ads to match your interests. It also helps us to measure
          the effectiveness of ad campaigns and is used to serve you with
          advertising that is more relevant to you.
        </p>
        <h4>Law Enforcement</h4>
        <p>
          Please note that EveryPast does allow law enforcement to use any
          public information on EveryPast to investigate certain crimes,
          specifically homicide and sexual assault, and for the purpose of
          identifying human remains. EveryPast does not permit law enforcement
          to use any information on our site to investigate any crimes beyond
          homicide and sexual assault.
        </p>
        <p>
          To provide our Users with the greatest protection under the law, we
          require all government agencies seeking access to EveryPast customers’
          private data to follow valid legal process. We do not voluntarily
          disclose any of our customers' private data to law enforcement or any
          government agency.
        </p>
        <p>
          If we are compelled to disclose your private Personal Information to
          law enforcement, we will do our best to provide you with advance
          notice, unless we are prohibited under the law from doing so. In the
          interest of transparency, EveryPast will produce a Transparency Report
          where we list the number of valid law enforcement requests for user
          data across all our sites.
        </p>
        <h4>Other Legal or Regulatory Process</h4>
        <p>
          We may share your Personal Information if we believe it is reasonably
          necessary to:
        </p>
        <li>Comply with valid legal process (e.g., subpoenas, warrants);</li>
        <li>
          Enforce or apply the EveryPast{" "}
          <a href='/terms'>Terms and Conditions</a>;
        </li>
        <li>Protect the security or integrity of the Services; or</li>
        <li>
          Protect the rights, property, or safety, of EveryPast, our employees
          or Users.
        </li>
        <h4>If EveryPast is Acquired</h4>
        <p>
          If EveryPast or its businesses are acquired or transferred (including
          in connection with bankruptcy or similar proceedings), we will share
          your Personal Information with the acquiring or receiving entity. The
          promises in this Privacy Statement may continue to apply to your
          Personal Information that is transferred to the new entity.
        </p>
        <h4>A Note About Aggregated Data</h4>
        <p>
          EveryPast may disclose user information in an aggregated form as part
          of the Services or our marketing, or in scientific publications
          published by us or our research partners. Such disclosure will never
          include Personal Information.
        </p>
        <a name='rightsandchoices'></a>
        <h3>8. Your Rights and Choices Regarding Your Personal Information</h3>
        <p>
          All Users (as defined in our <a href='/terms'>Terms and Conditions</a>
          ) may use our online tools to 1) request a report of what Personal
          Information they have provided to us, 2) download a copy of their
          biographical data, and 3) delete their biographical answers,
          biographical submissions, or account. Some laws provide Users with
          specific rights regarding their Personal Information. For example:
        </p>
        <p>
          <strong>Right of Access/To Know</strong> – You may have a right to
          request access to your Personal Information and to be provided with a
          copy of certain information including the categories of your Personal
          Information we collect and disclose. To request such copy, email us at{" "}
          <a href='mailto:info@everypast.com'>info@everypast.com</a>.
        </p>
        <p>
          <strong>Right of Rectification/Correction</strong> – You may have the
          right to request that we rectify or correct inaccurate Personal
          Information about you.
        </p>
        <p>
          <strong>Right of Erasure/Deletion</strong> – You have the right, in
          certain cases, to request that we delete information that someone else
          has posted about you, provided there are valid grounds for doing so
          and subject to applicable law. See <a href='#delete'>Section 10</a>{" "}
          for information on how to delete your Personal Information.
        </p>
        <p>
          <strong>Right to data portability</strong> – You may have the right to
          receive certain of your Personal Information in a format that can be
          transmitted to another data controller.
        </p>
        <p>
          You can exercise these rights by using the tools described below or by
          contacting EveryPast. Details and options for accessing this
          information are listed below.
        </p>
        <h4>EveryPast</h4>
        <p>
          You can access and update the Personal Information (such as your email
          address, username, profile information, etc.) that you provide to
          EveryPast at any time in the following sections of the privacy
          settings:
        </p>
        <li>Profile;</li>
        <li>Account.</li>
        <p>
          To learn how to manage the privacy settings for any life stories you
          have created on EveryPast, you can click{" "}
          <a href='https://www.youtube.com/watch?v=I-SwgHOy2Uc' target='_blank'>
            here
          </a>
          .
        </p>
        <h4>Related Brands</h4>
        <p>
          EveryPast strives to make it simple for you to manage your privacy
          across the Services.
        </p>
        <h4>Mobile Apps</h4>
        <p>
          You can also control your information using the settings available in
          our mobile applications, such as an EveryPast app that may be
          developed in the future.
        </p>
        <h4>Advertising</h4>
        <p>
          We use cookies and similar tracking technologies and integrations with
          third party marketing and advertising partners to collect data about
          your interests or behaviors to serve you targeted ads. You may change
          your settings as described in our{" "}
          <a href='/cookiepolicy'>Cookie Policy</a>. For information on “Do Not
          Track” please see our <a href='/cookiepolicy'>Cookie Policy</a>.
        </p>
        <h4>Biographical Information Download</h4>
        <p>
          EveryPast allows you to download your biographical information.
          Contact us at{" "}
          <a href='mailto:info@everypast.com'>info@everypast.com</a> to request.
        </p>
        <a name='retention'></a>
        <h3>9. What Are Our Retention Practices?</h3>
        <p>
          EveryPast Services are fundamentally premised on the notion that the
          personal voyage of self-discovery is not a one-time event and
          continues over lengthy periods of time—possibly lifetimes.
          Additionally, and with particular regard to our subscribers and Users
          who pay fees or purchase subscriptions, the ongoing enhancement of our
          collections of historical records provide benefits and insights to our
          Users over time. As a result, our retention practices reflect this
          ongoing value by retaining user accounts on our system until our Users
          inform us of their desire to delete their data or close their
          accounts.
        </p>
        <h4>Account and Profile</h4>
        <p>
          EveryPast will retain the Personal Information you provide while
          creating your account and your profile until such time as you delete
          your account.
        </p>
        <h4>Biographical Information</h4>
        <p>
          Your biographical data and Personal Information is kept until you
          delete your answer(s), your submission(s), your public profile or
          sub-profile(s), or your account.
        </p>
        <h4>Related Brands</h4>
        <p>
          Some of our related brands can have their own account logins and will
          retain the Personal Information you provide while creating your
          account and your profile as needed to provide you with continuous and
          updated Services until such time as you ask us to delete it.
        </p>
        <h4>Usage Information</h4>
        <p>
          We retain usage information (e.g., visits to sites) in a
          depersonalized or aggregated form. Once aggregated, this information
          ceases to be personal and will not be subject to user deletion
          requests.
        </p>
        <a name='delete'></a>
        <h3>10. How Can I Delete My Personal Information?</h3>
        <p>
          You can delete your Personal Information from EveryPast at any time.
        </p>
        <h4>Personal Information</h4>
        <p>
          If you have shared information with other Users (for example, by
          making your profile public or by sharing your biographical information
          directly with other Users, website visitors, or even non website
          visitors), EveryPast will not be able to remove any copies of
          information that other Users or individuals may have retained, which
          can only be removed by your contacting the other User or individual
          and asking them to delete it.
        </p>
        <p>
          Please direct any request to remove information from linked archival
          records to the responsible archival entity.
        </p>
        <p>
          We will consider requests for removal of Personal Information from the
          searchable indexes of the records we hold on a case-by-case basis in
          accordance with law.
        </p>
        <h4>General</h4>
        <p>
          Please note that there may be some latency in deleting your Personal
          Information from our backup systems after it has been deleted from our
          production, development, analytics, and/or research systems. Also, our
          partners may retain information they receive from us in order to
          comply with laws or regulations that may require them to do so.
          EveryPast may also retain certain information as reasonably necessary
          to comply with our legal obligations (including law enforcement
          requests), resolve disputes, maintain security, prevent fraud and
          abuse, as well as to comply with tax, payment industry, securities,
          and regulatory compliance requirements.
        </p>
        <h4>Related Brands</h4>
        <p>
          To delete information from our related brands, you can always contact
          us at the related brand and request that your Personal Information be
          deleted from that service.
        </p>
        <a name='security'></a>
        <h3>11. Security</h3>
        <p>
          EveryPast maintains a comprehensive information security program
          designed to protect our customers’ Personal Information using
          administrative, physical, and technical safeguards.
        </p>
        <p>
          The specific security measures used are based on the sensitivity of
          the Personal Information collected. We have measures in place to
          protect against inappropriate access, loss, misuse, or alteration of
          Personal Information under our control.
        </p>
        <p>
          EveryPast regularly reviews our security and privacy practices and
          enhances them as necessary to help ensure the integrity of our systems
          and your Personal Information.
        </p>
        <p>
          We use secure server software to encrypt Personal Information, and we
          only partner with security companies that meet and commit to our
          security standards. While we cannot guarantee that loss, misuse or
          alteration of data will not occur, we use reasonable efforts to
          prevent this.
        </p>
        <p>
          It is also important for you to guard against unauthorized access to
          your Personal Information by maintaining strong passwords and
          protecting against the unauthorized use of your own computer or
          device.
        </p>
        <a name='transfers'></a>
        <h3>12. International Data Transfers</h3>
        <p>
          As EveryPast expands to multiple countries, we may transfer your
          Personal Information to countries other than your own country,
          including to the United States. Countries which are outside the EEA,
          Switzerland, or the United Kingdom may not offer the same level of
          data protection as in your home country.
        </p>
        <a name='changes'></a>
        <h3>13. Changes to This Statement</h3>
        <p>
          We may modify this Privacy Statement at any time, but we will provide
          prominent advance notice of any material changes to this Statement,
          such as posting a notice through the Services, on our websites, or
          sending you an email, to provide you the opportunity to review the
          changes and choose whether to continue using the Services.
        </p>
        <p>
          We will also notify you of non-material changes to this Statement as
          of their effective date by posting a notice through the Services, on
          our websites, or sending you an email.
        </p>
        <p>
          If you object to any changes, you may delete your account as described
          in <a href='#delete'>Section 10</a>.
        </p>
        <a name='legalbases'></a>
        <h3>
          14. Legal Bases Under EU General Data Protection Regulation for
          Processing Personal Information of EU Residents.
        </h3>
        <p>
          Under EU and UK law, we are required to specify the purposes for which
          we process your Personal Information and the legal bases which we rely
          on to do this. EveryPast relies on a number of legal bases to collect
          and process your Personal Information and Genetic Information.
        </p>
        <a name='identitycontact'></a>
        <h3>15. Identity and Contact Details of the Data Controller</h3>
        <p>
          Koerzell International LLC is responsible for the use of your data and
          for responding to any requests related to your Personal Information.
        </p>
        <h4>For EveryPast Users</h4>
        <p>
          <a href='mailto:info@everypast.com'>info@everypast.com</a>
        </p>
        <p>
          Koerzell International LLC
          <br />
          Attn: EveryPast Privacy
          <br />
          3170 Willow Bend Dr.
          <br />
          St Charles, MO 63303
        </p>
        <h4>For EveryPast Customers</h4>
        <p>
          EveryPast customers can reach us by emailing us at{" "}
          <a href='mailto:info@everypast.com'>info@everypast.com</a>.
        </p>
        <h4>Law Enforcement Requests</h4>
        <p>
          Requests for information from law enforcement bodies should follow
          EveryPast’s Guide for Law Enforcement.
        </p>
        <a href='california'></a>
        <h3>16. California Resident Additional Privacy Statement</h3>
        <p>
          EveryPast provides this California Resident Additional Privacy
          Statement (“<strong>California Statement</strong>”), applicable solely
          to California residents, as a supplement to our Privacy Statement, to
          provide information as to how you may exercise your rights under the
          California Consumer Privacy Act (“<strong>CCPA</strong>”). This
          California Statement covers all EveryPast websites, services, and
          mobile apps that link to this California Statement (throughout,
          collectively, as applicable “<strong>EveryPast</strong>” “
          <strong>we</strong>” or “<strong>our</strong>”). It applies to the
          Personal Information (defined above) we collect from your use of our
          Services and through any other means by which you engage with us.
        </p>
        <h3>16.1 Categories of Personal Information</h3>
        <p>
          EveryPast collects, and has collected in the 12 months prior to the
          effective date of this California Statement, the following categories
          of Personal Information:
        </p>
        <li>
          <u>Identifiers</u>, such as name, unique personal identifier, online
          identifier, IP address, your mobile device identifier, email address,
          account name.
        </li>
        <li>
          <u>
            Categories of personal information described in subdivision (e) of
            Section 1798.80 not covered by an existing category
          </u>{" "}
          (medical information, physical characteristics or descriptions).
        </li>
        <li>
          <u>
            Characteristics of protected classes under California or federal law
          </u>
          , such as assigned sex at birth, year of birth, and medical
          conditions. This also includes any other User Provided Content
          (defined above) you choose to share with us that is considered a
          legally protected class under California or federal law.
        </li>
        <li>
          <u>Commercial information</u>, such as products or services purchased,
          obtained, or considered or other purchasing or consuming histories or
          tendencies.
        </li>
        <li>
          <u>Internet usage information</u>, such as your browsing history,
          search history, and information regarding your interaction with our
          sites and advertisements (also described above).
        </li>
        <li>
          <u>Geolocation data</u>, such as location of your device or computer
          and any metadata associated with digital photographs uploaded to
          EveryPast, including the location, date, and time the photo was taken.
        </li>
        <li>
          <u>Sensory data</u>, such as audio, electronic, and visual information
          (e.g., audio or video recordings you upload, recordings of calls with
          EveryPast Member Services or information you voluntarily share when
          doing consumer insights research with us).
        </li>
        <li>
          <u>Inferences</u> derived from Personal Information, such as to
          suggest familial relationships and to create consumer profiles for the
          purposes of research, product development and marketing. Please note
          as stated in Section 16.6 below, we do not sell your Personal
          Information.
        </li>
        <p>
          Note that some categories of information are collected only if you use
          certain of our Services.
        </p>
        <h3>16.2 Business Purposes</h3>
        <p>
          EveryPast uses the categories of Personal Information described in
          Section 16.1 for the following business purposes:
        </p>
        <li>
          Providing, personalizing, improving, updating, and expanding
          EveryPast’s Services;
        </li>
        <li>Communicating to you about the Services; and</li>
        <li>
          Marketing new products and offers from us or our business partners
          based on your interests.
        </li>
        <h3>16.3 Categories of Sources of Personal Information</h3>
        <p>
          EveryPast collects, and has collected in the 12 months prior to the
          effective date of this California Statement, categories of Personal
          Information described in Section 16.1 from the following categories of
          sources:
        </p>
        <li>
          You, our user/visitor—this source of Personal Information depends on
          your use of the Services, including your User Provided Content
          (defined above);
        </li>
        <li>
          EveryPast and EveryPast Companies (see{" "}
          <a href='#sharing'>Section 7</a> for more information);
        </li>
        <li>Public records;</li>
        <li>Historical records; and,</li>
        <li>Third parties.</li>
        <h3>
          16.4 Categories of Third-Parties with Whom We Share Personal
          Information
        </h3>
        <p>
          EveryPast shares, and has shared in the 12 months prior to the
          effective date of this California Statement, with the following
          categories of third parties the correlating categories of Personal
          Information:
        </p>
        <h4>
          Other EveryPast Users (if you shared your Personal Information on
          EveryPast)
        </h4>
        <li>Identifiers;</li>
        <li>
          Categories of personal information described in Section 1798.80(e) of
          the California Civil Code;
        </li>
        <li>
          Characteristics of protected classes under California or federal law;
          and,
        </li>
        <h4>EveryPast Companies</h4>
        <li>Identifiers;</li>
        <li>
          Categories of personal information described in Section 1798.80(e) of
          the California Civil Code;
        </li>
        <li>
          Characteristics of protected classes under California or federal law;
        </li>
        <li>Commercial information;</li>
        <li>Internet usage information;</li>
        <li>Geolocation information;</li>
        <li>Sensory data; and,</li>
        <li>Inferences</li>
        <h4>Service Providers</h4>
        <li>
          Identifiers and categories of personal information described in
          Section 1798.80(e) of the California Civil Code such as email address
          and unique personal identifiers generated by EveryPast (tube code);
        </li>
        <li>Commercial information;</li>
        <li>Internet usage information;</li>
        <li>Sensory data; and,</li>
        <li>Inferences.</li>
        <h4>Law Enforcement or Other Regulatory Bodies</h4>
        <p>
          Any Personal Information of yours that you post publicly on EveryPast
          is available to assist law enforcement in very specific circumstances:
          the identification of human remains, homicide investigations, and
          sexual assault investigations. This does not apply to Personal
          Information that you have set as private. We do not voluntarily share
          your private Personal Information with law enforcement or regulatory
          bodies. We require a valid legal process as described in{" "}
          <a href='#sharing'>Section 7</a> above before we would provide any
          private data to law enforcement. For information on what data has been
          shared, see our Transparency Reports (if we have not shared any data
          with law enforcement or regulatory bodies yet, then there may not be
          any Transparency Reports yet).
        </p>
        <h3>16.5 Exercising Your Rights Under CCPA.</h3>
        <h4>16.5.1 Right to Know</h4>
        <p>
          The CCPA gives California consumers the right to request that we
          disclose what Personal Information we collect, use, disclose, or sell.
          This includes the right to request the categories of Personal
          Information we have collected about them, the categories of sources of
          Personal Information, the business purpose for collecting Personal
          Information, the categories of third parties with whom we share
          categories of Personal Information, and the specific pieces of
          Personal Information we have collected about the requestor.
        </p>
        <p>
          If you are submitting a request to know on your own behalf, you may
          make your request by email.
        </p>
        <p>
          To email your request to know, email us at{" "}
          <a href='mailto:info@everypast.com'>info@everypast.com</a>. We are
          required to verify your identity before providing you with a copy of
          your data. We will require you to provide proof of identity and
          California address (e.g., you may be required to provide a copy of
          your government ID, which will be used solely to verify your identity
          and address).
        </p>
        <p>
          If you are an “Authorized Agent” pursuant to the CCPA and are
          submitting a request to know on behalf of one of our users, see
          Section 16.5.3 below.
        </p>
        <h4>16.5.2 Right to Delete</h4>
        <p>
          The CCPA gives California consumers the right to request that we
          delete your Personal Information.
        </p>
        <p>
          If you are submitting a request to delete on your own behalf, there
          are two ways to make your request: online or by email. Note that to
          delete your Personal Information, you must delete your account and
          that once an account deletion request is completed, this process is
          irreversible. Your information (including, but not limited to,
          biographical data, records, and photos) will be permanently deleted.
        </p>
        <p>
          To submit your request for deletion from EveryPast online, click the
          "Delete Profile" button on your "My Account" page.
        </p>
        <p>
          To email your request to delete, email us at{" "}
          <a href='mailto:info@everypast.com'>info@everypast.com</a>. We are
          required to verify your identity before we delete your data. We will
          require you to provide proof of identity and California address (e.g.,
          you may be required to provide a copy of your government ID, which
          will be used solely to verify your identity and address).
        </p>
        <p>
          If you are an “Authorized Agent” pursuant to the CCPA and are
          submitting a request to know on behalf of one of our users, see
          Section 16.5.3 below.
        </p>
        <h4>16.5.3 Authorized Agents</h4>
        <p>
          The CCPA allows California residents to use “authorized agents” to
          make requests to know and requests for deletion. To use an authorized
          agent, email us at{" "}
          <a href='mailto:info@everypast.com'>info@everypast.com</a>. Note that
          even if a consumer chooses to use an authorized agent for a request,
          the consumer will still need to work directly with us to provide items
          3 and 4 below:
        </p>
        <p>
          1. Proof that the authorized agent is registered with the Secretary of
          State to conduct business in California.
          <br />
          2. Signed permission from the consumer allowing the authorized agent
          to act on the consumer’s behalf.
          <br />
          3. Verification of the consumer’s identity (to be made directly by the
          consumer to us). We will require proof of identity and California
          address (e.g., a copy of your government ID, which will be used solely
          to verify your identity and address).
        </p>
        <p>
          <strong>Please note</strong>: The report will be delivered to the
          consumer via their EveryPast account. Also note that the report does
          not include a copy of the consumer’s biographical data. If the
          consumer wants to download their biographical data, see Section 16.5.1
          above.
        </p>
        <h3>16.6 Selling</h3>
        <p>
          EveryPast does not sell your Personal Information, and has not sold it
          in the 12 months prior to the effective date of this California
          Statement.
        </p>
        <h3>16.7 Non-discrimination</h3>
        <p>
          We will not discriminate against you for exercising any of your rights
          under the CCPA.
        </p>
        <h3>16.8 California's Shine the Light Law</h3>
        <p>
          California Civil Code Section 1798.83, known as the “Shine the Light”
          law, permits Users who are California residents to request and obtain
          from us a list of what Personal Information (if any) we disclosed to
          third parties for their direct marketing purposes in the preceding
          calendar year and the names and addresses of those third parties.
          Requests may be made only once a year and are free of charge. Under
          Section 1798.83, EveryPast currently does not share any Personal
          Information with third parties for their own direct marketing
          purposes.
        </p>
        <h3>16.9 Contact Us</h3>
        <p>
          If you have questions about our CCPA or California-related privacy
          policies or practices, please contact us at{" "}
          <a href='mailto:info@everypast.com'>info@everypast.com</a>.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicyPage;
