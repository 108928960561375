// import Card from "../ui/Card";
import React from "react";

import classes from "./LandingCard.module.scss";

import { Link } from "react-router-dom";

import slide1 from "./slide1.svg";
import slide2 from "./slide2.svg";
import slide3 from "./slide3.svg";

const LandingCard = ({ id, title, desc, btnText, image }) => {
  const slide = () => {
    if (id === 1) {
      return (
        <div className={classes.imageBlock}>
          <img src={slide1} alt='Everypast' />
        </div>
      );
    } else if (id === 2) {
      return (
        <div className={classes.imageBlock}>
          <img src={slide2} alt='Everypast' />
        </div>
      );
    } else {
      return (
        <div className={classes.imageBlock}>
          <img src={slide3} alt='Everypast' />
        </div>
      );
    }
  };

  return (
    <div className={classes.card}>
      <div className={classes.contentBlock}>
        <div className={classes.textBlock}>
          <h1>{title}</h1>
          <p>{desc}</p>
          <Link to='/register'>
            <button>{btnText}</button>
          </Link>
        </div>

        {slide()}
      </div>
    </div>
  );
};

export default LandingCard;
