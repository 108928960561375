import React, { useEffect, useState } from "react";
import styles from "./AccountPage.module.scss";
import { Redirect } from "react-router-dom";

import TextField from "@material-ui/core/TextField";
import Switch from "@material-ui/core/Switch";
import { FormGroup } from "@material-ui/core";
import { FormControlLabel } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import Modal from "@material-ui/core/Modal";
import MuiAlert from "@material-ui/lab/Alert";

import { useDispatch, useSelector } from "react-redux";
import {
  getAccount,
  getUser,
  updatePrivacy,
  updateEmail,
  updatePassword,
} from "../actions/user";
import { removeProfile, removeResponse } from "../actions/auth";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}

// STYLE FOR MODAL
const useDyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "90%",
    },
  },
  paper: {
    position: "absolute",
    width: "70%",
    maxWidth: "600px",
    backgroundColor: theme.palette.background.paper,
    border: "none",
    borderRadius: "15px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const AccountPage = () => {
  const dyles = useDyles();

  const dispatch = useDispatch();

  const userEmail = useSelector((state) => state.user.email);
  const userBirthdate = useSelector((state) => state.user.bday);
  const publicValue = useSelector((state) => state.user.public);
  const auth = useSelector((state) => state.auth);

  const [isPublic, setisPublic] = useState(publicValue);
  // console.table(isPublic);
  //   console.table(user.public);

  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = useState(getModalStyle);

  const [open, setOpen] = useState(false);
  const [editPasswordClicked, setEditPasswordClicked] = useState(false);
  const [editEmailClicked, setEditEmailClicked] = useState(false);

  const [response, setResponse] = useState("");

  const [password, setPassword] = useState({
    password: "",
  });
  const [email, setEmail] = useState({
    email: userEmail,
  });

  const [helperText, setHelperText] = useState("");
  const [isComsChecked, setIsComsChecked] = useState(false);

  // Open Modal
  const handleOpen = () => {
    setOpen(true);
  };

  // Close Modal
  const handleClose = () => {
    setOpen(false);
    // setIsSubmitted(false);
    // setChangeRender(changeRender + 1);
  };

  const handleChecked = () => {
    dispatch(updatePrivacy());
    setResponse(auth.response);
    setisPublic(!isPublic);
    // console.table(isPublic);
    setTimeout(function () {
      dispatch(removeResponse());
    }, 3000);
  };

  const handleSubmitEmail = () => {
    dispatch(updateEmail(email));
    setResponse(auth.response);
    setTimeout(function () {
      dispatch(removeResponse());
    }, 3000);
  };

  const handleSubmitPassword = () => {
    if (password.length >= 6) {
      dispatch(updatePassword(password));
      setResponse(auth.response);
      setTimeout(function () {
        dispatch(removeResponse());
      }, 3000);
    } else {
      setHelperText("Password must be 6 or more characters");
    }
  };

  const handleDelete = () => {
    dispatch(removeProfile());
  };

  // useEffect(() => {
  //   removeResponse();
  // }, []);

  useEffect(() => {
    dispatch(removeResponse());
  }, []);

  useEffect(() => {
    setResponse(auth.response);
    dispatch(getUser(auth.id));
    setisPublic(publicValue);
  }, [dispatch, publicValue, auth.response]);

  useEffect(() => {
    setEmail({ email: userEmail });
  }, [userEmail]);

  // console.log("USER IS PUBLIC?:");
  // console.table(isPublic);
  //   console.table(user.public);

  const comTerms = <p>I agree to receive communications from EveryPast</p>;

  // DELETE PROFILE MODAL
  const body = (
    <div style={modalStyle} className={dyles.paper}>
      <div className={styles.thanksContainer}>
        <h1>Are you sure you want to delete your profile?</h1>
        <h2>
          Deleting your profile will completely remove all traces of your
          history from this website, including all secondary accounts. If you
          want to login again you will need to create a new account.
        </h2>
        <button
          className={styles.button}
          type='submit'
          onClick={() => setOpen(false)}
        >
          Nevermind
        </button>
        <button
          className={styles.buttonDelete}
          type='submit'
          onClick={handleDelete}
        >
          Yes, delete my profile
        </button>
      </div>
    </div>
  );

  const editEmailBody = (
    <>
      <form className={dyles.root} noValidate autoComplete='off'>
        <TextField
          id='email'
          label='New Email'
          // multiline
          rows={1}
          // defaultValue={userEmail}
          variant='outlined'
          value={email.email}
          onChange={(e) => setEmail({ email: e.target.value })}
        />
      </form>
      <button
        className={styles.submitButton}
        type='submit'
        onClick={handleSubmitEmail}
      >
        Submit Changes
      </button>
    </>
  );

  const editPasswordBody = (
    <>
      <form className={dyles.root} Validate autoComplete='off'>
        <TextField
          FormHelperTextProps={{
            className: styles.helperText,
          }}
          id='password'
          label='New Password'
          // multiline
          error={helperText.length === 0 ? false : true}
          rows={1}
          defaultValue=''
          variant='outlined'
          helperText={helperText}
          // value={name.first_name}
          onChange={(e) => setPassword({ password: e.target.value })}
        />
      </form>
      <button
        className={styles.submitButton}
        type='submit'
        onClick={handleSubmitPassword}
      >
        Submit Changes
      </button>
    </>
  );

  if (auth.isAuthenticated === false) {
    return <Redirect to='/login' />;
  }

  return (
    <div className={styles.container}>
      <div className={styles.divContainer}>
        <h2>Account Settings</h2>
        <div className={styles.line}></div>
        <div className={styles.textBox}>
          <div className={styles.textFlex}>
            <h3>Email:</h3>
            {editEmailClicked ? editEmailBody : <h2>{userEmail}</h2>}
            <small onClick={() => setEditEmailClicked(!editEmailClicked)}>
              {editEmailClicked ? "Close" : "Edit"}
            </small>
          </div>

          <div className={styles.textFlex}>
            <h3>Password:</h3>
            {editPasswordClicked ? editPasswordBody : <h2>*********</h2>}

            <small onClick={() => setEditPasswordClicked(!editPasswordClicked)}>
              {editPasswordClicked ? "Close" : "Edit"}
            </small>
          </div>

          <div className={styles.textFlex}>
            <h3>Profile View:</h3>
            <FormGroup className={styles.switch}>
              <FormControlLabel
                className={styles.switchText}
                control={
                  <Switch
                    defaultValue={isPublic}
                    onChange={handleChecked}
                    // checked={isPublic}
                    // onChange={(e) => setisPublic((prevCheck) => !prevCheck)}
                    // onChange={() => handleChecked()}
                  />
                }
                label={isPublic ? "Currently Public" : "Currently Private"}
              />
            </FormGroup>
          </div>

          {/* ADD WHEN LAWRENCE ADDS BACKEND */}
          {/* <div className={styles.textFlex}>
            <FormControlLabel
              control={
                <Checkbox
                  value='allowExtraEmails'
                  color='primary'
                  checked={isComsChecked}
                />
              }
              label={comTerms}
              onClick={() => setIsComsChecked(!isComsChecked)}
            />
          </div> */}

          {/* SET THE RESPONSE */}
          {response && (
            <Alert
              className={dyles.alert}
              severity='success'
              onClick={() => {
                setResponse(null);
                dispatch({ type: "ADD_RESPONSE", payload: response });
              }}
            >
              {auth.response || response}
            </Alert>
          )}

          <div className={styles.delete} onClick={handleOpen}>
            DELETE PROFILE <DeleteIcon className={styles.deleteIcon} />
          </div>
          <div
            style={{
              textAlign: "center",
              padding: "5px",
              paddingTop: "10px",
              fontSize: "13px",
            }}
          >
            Careful! This cannot be undone!
          </div>
        </div>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-describedby='simple-modal-description'
      >
        {body}
      </Modal>
    </div>
  );
};

export default AccountPage;
