import React from "react";
import classes from "./TermsPage.module.scss";

const TermsPage = () => {
    return (
        <div className={classes.container}>
            <div>
                <h2>EveryPast Terms and Conditions</h2>
                <div className={classes.line}></div>
                <p>
                    <strong>Effective Date:</strong> 24 February 2022
                </p>
                <h2>
                    <strong>1. &nbsp;Introduction and Services:</strong>
                </h2>
                <p>
                    Welcome to EveryPast! By using any of the EveryPast websites, services,
                    and mobile apps that link to the term EveryPast, as well as other
                    related brands (the &ldquo;Services&quot;), you agree to these Terms and
                    Conditions (the &ldquo;Terms&rdquo;). Any new features included in the
                    Services in the future will also be subject to these Terms.
                </p>
                <p>
                    You also agree to our&nbsp;<a href="/cookiepolicy">Cookie Policy</a>,&nbsp;Renewal and Cancellation
                    Terms, and&nbsp;EveryPast Community Rules, each of which are
                    incorporated herein by reference. Please read these documents and
                    our&nbsp;<a href="/privacypolicy">Privacy Statement</a>&nbsp;(which describes how we collect,
                    process, use, and share your data) carefully because they contain
                    important information about your rights and responsibilities when you
                    use the Services. You are entering into these Terms with a specific
                    EveryPast entity depending on what Service you are using and where you
                    are geographically located (see&nbsp;EveryPast Companies&nbsp;below for
                    more information).
                </p>
                <p>
                    Additional terms may apply which will be disclosed to you when you sign
                    up for a new Service or take advantage of a new promotion or special
                    offer, which terms become part of your agreement with us if you use
                    those Services, promotions, or offers. If any additional terms conflict
                    with these Terms, those additional terms will prevail. If you have any
                    questions about these Terms or our Services, please&nbsp;contact us.
                </p>
                <p>&nbsp;</p>
                <p>
                    To help you to read these Terms, we have broken them down into the
                    following sections:
                </p>
                <ul>
                    <li>
                        <p>1. &nbsp;Introduction and Services</p>
                        <ul>
                            <li>
                                <p>1.1 &nbsp;Eligibility to Use the Services</p>
                            </li>
                            <li>
                                <p>1.2&nbsp;Use of the Services</p>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <p>2. &nbsp;Content</p>
                        <ul>
                            <li>
                                <p>2.1 &nbsp;EveryPast Content</p>
                            </li>
                            <li>
                                <p>2.2 &nbsp;Your Content</p>
                                <ul>
                                    <li>
                                        <p>2.2.1 &nbsp;Personal Information</p>
                                    </li>
                                    <li>
                                        <p>2.2.2 &nbsp;User Provided Content</p>
                                    </li>
                                    <li>
                                        <p>2.2.3 &nbsp;Ownership of Your Content</p>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <p>2.3 &nbsp;Copyright and Trademarks</p>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <p>3.&nbsp;Renewals and Cancellations for EveryPast Services</p>
                    </li>
                    <li>
                        <p>4.&nbsp;Termination or Suspension of Your Account</p>
                    </li>
                    <li>
                        <p>5.&nbsp;Warranty</p>
                    </li>
                    <li>
                        <p>6.&nbsp;Limit of Liability</p>
                    </li>
                    <li>
                        <p>7.&nbsp;Your Indemnity</p>
                    </li>
                    <li>
                        <p>8.&nbsp;Services Offered by Other Companies</p>
                    </li>
                    <li>
                        <p>9.&nbsp;Controlling Law</p>
                    </li>
                    <li>
                        <p>
                            10.&nbsp;Dispute Resolution, Arbitration and Class Action Waiver
                        </p>
                    </li>
                    <li>
                        <p>11.&nbsp;Miscellaneous</p>
                    </li>
                </ul>
                <p>&nbsp;</p>
                <p>
                    1.1. &nbsp;<u>Eligibility to Use the Services</u>. Unregistered guests,
                    free registered guests, and paid subscribers are all &ldquo;
                    <strong>Users</strong>&rdquo; under these Terms. You may need to create
                    an account to use the Services. You must provide accurate, complete and
                    current information when you register.
                </p>
                <p>
                    If you are between the ages of 13 and 18, you may use the Services
                    (excluding any Services requiring a DNA test) with your parent&apos;s or
                    guardian&apos;s permission. Children under the age of 13 are not
                    permitted to use any Services. We do not knowingly seek or collect any
                    Personal Information directly from children under the age of 13. If
                    EveryPast becomes aware that we have unknowingly collected any personal
                    data directly from a child under the age of 13, we will take
                    commercially reasonable efforts to delete such data from our system.
                </p>
                <p>
                    1.2. <u>Use of the Services</u>
                </p>
                <p>
                    In exchange for your access to the Services, you agree that you will:
                </p>
                <ul>
                    <li>
                        <p>
                            Comply with EveryPast&rsquo;s&nbsp;<a href="/cookiepolicy">Cookie Policy</a>,&nbsp;Renewal
                            and
                            Cancellation Terms&nbsp;and&nbsp;Community Rules;
                        </p>
                    </li>
                    <li>
                        <p>Comply with all applicable laws;</p>
                    </li>
                    <li>
                        <p>
                            Be responsible for all usage and activity on the Services made via
                            your account or with your log in information;
                        </p>
                    </li>
                    <li>
                        <p>
                            Not upload, display or otherwise provide on or through the Service any content that: (i) is
                            libelous, defamatory, abusive, threatening, harassing, hateful, offensive or otherwise
                            violates any law or infringes upon the right of any third party (including copyright,
                            trademark, privacy, publicity or other personal or proprietary rights); or (ii) in
                            EveryPast’s sole judgment, is objectionable or which restricts or inhibits any other person
                            from using the Service or which may expose EveryPast or its users to any harm or liability
                            of any kind.
                        </p>
                    </li>
                    <li>
                        <p>
                            Contact us&nbsp;if you suspect your account has been used without
                            your authorization or you believe your user name and password have
                            been stolen;
                        </p>
                    </li>
                    <li>
                        <p>
                            Not resell the Services or resell, reproduce, or publish any content
                            or information found on the Services, except as explicitly described
                            in these Terms;
                        </p>
                    </li>
                    <li>
                        <p>
                            Not circumvent, disable or otherwise interfere with security-related
                            features of the Services; and,
                        </p>
                    </li>
                    <li>
                        <p>
                            Not circumvent, disable or otherwise interfere with features that
                            prevent or restrict use or copying of any content or enforce
                            limitations on use of the Services or the content therein, including
                            by using any self-developed or third-party developed bots, crawlers,
                            spiders, data miners, scraping, or other automatic access tools.
                        </p>
                    </li>
                </ul>
                <p>
                    We protect your information as described in our <a href="/privacypolicy">Privacy Statement</a>. If
                    you choose to download a copy of your data, that copy is not protected
                    by our security measures and you are solely responsible for storing,
                    securing, and protecting that downloaded data. EveryPast has no
                    responsibility if you elect to share or transfer your downloaded data
                    with others, either intentionally or inadvertently. Your data is
                    intended only for your personal use.
                </p>
                <p>&nbsp;</p>
                <h2>2. Content</h2>
                <p>
                    2.1. &nbsp;<u>EveryPast Content</u>: The Services contain documents,
                    records, photos, videos, indexes of content, and other content provided
                    by EveryPast (&ldquo;<strong>EveryPast Content</strong>.&rdquo;). All
                    EveryPast Content is owned by or licensed to us and may be used only in
                    accordance with these Terms, including EveryPast Content that may be in
                    the public domain (&ldquo;<strong>Public Domain Content</strong>
                    &rdquo;). You agree:
                </p>
                <ul>
                    <li>
                        <p>
                            To use the EveryPast Content only as necessary for your personal use
                            of the Services, your academic or scholarly use of the Services, or
                            your professional family history research;
                        </p>
                    </li>
                    <li>
                        <p>
                            To download the EveryPast Content only as search results relevant to
                            such research or where expressly permitted by EveryPast;
                        </p>
                    </li>
                    <li>
                        <p>
                            To keep all copyright and other proprietary notices on any EveryPast
                            Content you download or print;
                        </p>
                    </li>
                    <li>
                        <p>
                            Not to distribute, republish, or sell any EveryPast Content; and,
                        </p>
                    </li>
                    <li>
                        <p>
                            To&nbsp;contact us&nbsp;to obtain our written permission if you want
                            to use more than a small portion of individual documents or media
                            that are Public Domain Content.
                        </p>
                    </li>
                </ul>
                <p>&nbsp;</p>
                <p>
                    2.2. &nbsp;<u>Your Content</u>: EveryPast facilitates your providing a
                    variety of content into the Services, including Personal Information
                    (defined in the&nbsp;<a href="/privacypolicy">Privacy Statement</a>), content such as stories about
                    yourself, pictures, videos, stories about relatives (living or
                    deceased), your comments, community discussions, or about records and
                    responses to surveys and questionnaires (&ldquo;
                    <strong>User Provided Content</strong>&rdquo;).
                </p>
                <p>
                    2.2.1 &nbsp;<u>Personal Information</u>: For more information on the
                    types of Personal Information you may provide us and your respective
                    rights, see our&nbsp;<a href="/privacypolicy">Privacy Statement</a>.
                </p>
                <p>
                    2.2.2 &nbsp;<u>User Provided Content:&nbsp;</u>With respect to User
                    Provided Content, you agree that:
                </p>
                <ul>
                    <li>
                        <p>You are solely responsible for your User Provided Content;</p>
                    </li>
                    <li>
                        <p>
                            You have all the necessary legal rights to upload or post your User
                            Provided Content;
                        </p>
                    </li>
                    <li>
                        <p>
                            Any User Provided Content you provide complies with
                            the&nbsp;EveryPast Community Rules;
                        </p>
                    </li>
                    <li>
                        <p>
                            You will provide EveryPast, upon our request, with any documentation
                            necessary to evidence your compliance with these Terms; and
                        </p>
                    </li>
                    <li>
                        <p>
                            Any User Provided Content that you have shared publicly (e.g. by
                            including such User Provided Content in a public EveryPast profile,
                            as part of your public profile in one of the Services or in a public
                            posting on one of our Services) may be used by other users as part
                            of, or in conjunction with, the Services. We will not be required to
                            remove any User Provided Content that you have made public or has
                            otherwise been shared from the public profiles of other users.
                        </p>
                    </li>
                </ul>
                <p>
                    <strong>
                        EveryPast has no responsibility or liability related to User Provided
                        Content.
                    </strong>{" "}
                    We do not routinely monitor User Provided Content for violations of
                    these Terms, including the EveryPast Community Rules, but we reserve the
                    right to do so (including using automated monitoring tools). We reserve
                    the right, but do not have the obligation, to remove or disable access
                    to any User Provided Content that we believe violates these Terms,
                    including the EveryPast Community Rules. Serious or repeat violations or
                    offenses will subject the responsible User to account termination, at
                    EveryPast&rsquo;s sole discretion.
                </p>
                <p>
                    2.2.3 &nbsp;<u>Ownership of Your Content</u>: You own your Personal
                    Information and User Provided Content. You can delete your Personal
                    Information from EveryPast by logging into your Account Settings (for
                    additional information, see the&nbsp;<a href="/privacypolicy">Privacy Statement</a>). So long as
                    your
                    content remains on our system, we need certain rights from you for your
                    and others&rsquo; use of the Services. By using the Services, you grant
                    us the right to collect, host, transfer, process, analyze, communicate
                    and store your Personal Information, defined in these Terms and in
                    the&nbsp;<a href="/privacypolicy">Privacy Statement</a>,&nbsp;in order to (a) provide the Services
                    to
                    you and other Users, (b) for the purposes described in these Terms and
                    our&nbsp;<a href="/privacypolicy">Privacy Statement</a>, (c) to help our Users discover more about
                    their families and family histories and build their family trees, (d) to help our Users discover
                    more about
                    their local communities and their histories, (e) to help our Users better understand world history
                    and history in general, and
                    (f) for any other purpose to which you expressly agree, such as sharing
                    with others.
                </p>
                <p>
                    Before you interview other individuals, as part of the creation and/or maintenance of so-called
                    "Sub-Profiles," you must obtain your interviewee's lawful consent to have their biographical
                    information recorded on EveryPast, and you must agree to (1) not hold yourself out as an employee or
                    representative of EveryPast, unless you actually are a salaried employee of EveryPast; (2) promise
                    to treat your interviewee respectfully, and, when you record their answers, to avoid stereotypes,
                    misrepresentations, and manipulations of their words; (3) give your interviewee a way to get in
                    contact with you, in case they want to change, remove, or privatize any of their answers or other
                    information; and (4) read aloud to them, or show them, the following text, and confirm that they
                    understand it:
                    "• Your answers will help others understand your personal, family, and local histories, and the
                    world history that you have been a part of. • Your answers will be publicly available at
                    EveryPast.com. • You can ask me to make your answers private, to change any of your answers, or to
                    delete any of your answers, whenever you want, even after this interview is over. • You only have to
                    answer a question if you want to. • You can end the interview whenever you want."
                </p>
                <p>
                    Also, by submitting User Provided Content through any of the Services,
                    you grant EveryPast a sublicensable, worldwide, royalty-free license to
                    host, store, copy, publish, distribute, provide access to, create
                    derivative works of, and otherwise use such User Provided Content to the
                    extent and in the form or context we deem appropriate on or through any
                    media or medium and with any technology or devices now known or
                    hereafter developed or discovered. This includes the right for EveryPast
                    to copy, display, and index your User Provided Content. EveryPast will
                    own the indexes it creates. We will also have the right to continue to
                    use your User Provided Content, even if you stop using the Services, but
                    only as necessary for us to provide and improve the Services.
                </p>
                <p>
                    2.3 &nbsp;<u>Copyright and Trademark</u>: Each of the Services is
                    protected by copyright as a collective work or compilation, pursuant to
                    U.S. copyright laws, international conventions, and other copyright
                    laws. The trademarks, service marks, and logos contained in the Services
                    are owned by or licensed to us. We and our licensors retain title,
                    ownership, and all other rights and interests in and to all EveryPast
                    Content in the Services.
                </p>
                <p>
                    If you have concerns that User Provided Content posted by other Users
                    may infringe your rights, contain illegal material, or violate these
                    Terms, please&nbsp;contact us. We are also sensitive to the copyright
                    and other intellectual property rights of others. For complaints
                    regarding copyright infringement or illegal content, including any
                    Digital Millennium Copyright Act takedown requests, click&nbsp;here.
                </p>
                <p>&nbsp;</p>
                <h2>3. Renewals and Cancellations for EveryPast Services</h2>
                <p>
                    Your purchases of and subscription(s) to any of the Services are subject
                    to the&nbsp;Renewal and Cancellation Terms.
                </p>
                <p>&nbsp;</p>
                <h2>4. Termination or Suspension of Your Account</h2>
                <p>
                    We may limit, terminate, or suspend your access to the Services without
                    a refund if you breach or act inconsistently with the letter or spirit
                    of these Terms, including the&nbsp;EveryPast Community Rules. In such a
                    case, you will not be entitled to a refund of any fees previously paid.
                </p>
                <p>&nbsp;</p>
                <h2>5. Warranty</h2>
                <p>
                    While we hope you enjoy using our Services, there are things we
                    don&rsquo;t promise about our Services.
                </p>
                <p>
                    <strong>
                        Except as expressly set out in these Terms, we provide the Services
                        and the EveryPast Content to you on an &ldquo;AS-IS&rdquo; basis. To
                        the maximum extent permitted by law, we disclaim all warranties
                        express or implied, including the implied warranties of
                        non-infringement, merchantability, and fitness for a particular
                        purpose. We do not make any promises (a) about the EveryPast Content,
                        (b) about User Provided Content, (c) about the specific functionality
                        of the Services, (d) about the quality, accuracy, reliability, or
                        availability of the EveryPast Content or Services, or (e) that the
                        Services will be free from viruses or other harmful components.
                    </strong>
                </p>
                <p>
                    We are constantly changing and improving our Services, so we may add or
                    remove functionality or features from the Services and we may suspend or
                    stop a Service altogether.
                </p>
                <p>&nbsp;</p>
                <h2>6. Limit of Liability</h2>
                <p>
                    We limit our liability to the fullest extent allowed by law. We will not
                    be liable for any unintentional damage, any actual, incidental, or
                    consequential damage, or for any loss or claim of any kind. Some
                    jurisdictions do not allow us to have a broad limit on our liability. If
                    you live in one of those jurisdictions, some of these limitations may
                    not apply to you. If you are dissatisfied with any portion of the
                    Services or with any statement in these Terms, your sole remedy is to
                    stop using the Services and, if you are using any of our subscription
                    Services, cancel your subscription as described&nbsp;here. Our total
                    liability in any matter related to the Services or these Terms is
                    limited to the aggregate amount you paid us during the 12-month period
                    preceding the event giving rise to the liability. This limitation of
                    liability applies fully to residents of New Jersey.
                </p>
                <p>&nbsp;</p>
                <h2>7. Your Indemnity</h2>
                <p>
                    You agree that you will indemnify and hold EveryPast, and its affiliates
                    and subsidiaries their respective officers, directors, employees,
                    agents, successors, and assigns (the &ldquo;
                    <strong>EveryPast Parties</strong>&rdquo;) harmless from any claims,
                    damages, or other expenses (including attorney&rsquo;s fees) that result
                    from your use of the Services and (a) your violation of these Terms or
                    other documents incorporated herein by reference; (b) your violation of
                    another person&rsquo;s rights; or (c) any claim related to your User
                    Provided Content, including a claim that your User Provided Content
                    caused damage to another person. This indemnification obligation will
                    continue after you stop using the Services. In addition, you release the
                    EveryPast Parties from all claims, demands, actions, or suits in
                    connection with your User Provided Content, including any liability
                    related to our use or non-use of your User Provided Content, claims for
                    defamation, invasion of privacy, right of publicity, emotional distress,
                    or economic loss.
                </p>
                <p>&nbsp;</p>
                <h2>8. Services Offered by Other Companies</h2>
                <p>
                    Our Services may contain links to other sites operated by third parties,
                    including but not limited to, third-party sites that may display
                    EveryPast trademarks (&quot;<strong>Third-Party Site(s)</strong>&quot;).
                    These links are available for your convenience and are intended only to
                    enable access to these Third-Party Sites and for no other purpose. A
                    link to a Third-Party Site from our websites does not constitute
                    sponsorship, endorsement, approval, or responsibility for any
                    Third-Party Site. The terms and conditions of use and privacy statement
                    of a Third-Party Site may differ substantially from our Terms and
                    <a href="/privacypolicy">Privacy Statement</a>. Please read the terms and conditions of use and
                    privacy documentation for all Third-Party Sites carefully.
                </p>
                <p>
                    EveryPast does not warrant or make any representation about the
                    substance, quality, functionality, accuracy, fitness for a particular
                    purpose, merchantability, or any other representation about any
                    Third-Party Site or its content, products, and services. We have no
                    responsibility related to any Third-Party Site.
                </p>
                <p>&nbsp;</p>
                <h2>9. Controlling Law</h2>
                <p>
                    If you access the Services on our websites in the United States, the
                    laws of the State of Utah and as applicable, those of the United States
                    of America, govern these Terms and the use of the Services. All claims
                    not subject to arbitration and brought in the United States will be
                    subject to the jurisdiction of the courts of the State of Utah. If you
                    access the Services on our websites anywhere other than in the United
                    States, the laws of Ireland govern these Terms.
                </p>
                <p>&nbsp;</p>
                <h2>10. Dispute Resolution, Arbitration and Class Action Waiver</h2>
                <p>
                    <strong>
                        PLEASE READ THIS SECTION CAREFULLY. IT AFFECTS YOUR LEGAL RIGHTS,
                        INCLUDING YOUR RIGHT TO FILE A LAWSUIT IN COURT.
                    </strong>
                </p>
                <p>
                    We work hard to keep our customers satisfied. If a dispute arises
                    between you and EveryPast, our goal is to provide a cost-effective means
                    of quickly resolving the dispute. If you have any concern or dispute
                    about the Services, you agree to first try to resolve the dispute
                    informally by&nbsp;contacting us.
                </p>
                <h3>For U.S. Customers:</h3>
                <p>
                    You and EveryPast agree that these Terms affect interstate commerce and
                    that the Federal Arbitration Act governs the interpretation and
                    enforcement of these arbitration provisions.
                </p>
                <p>
                    If any dispute between us is not resolved within 30 days after
                    contacting us, then you and EveryPast agree that we will resolve it
                    through final and binding arbitration, with the following three
                    exceptions:
                </p>
                <ol>
                    <li>
                        <p>
                            You may assert your dispute, if it qualifies, in small claims court.
                        </p>
                    </li>
                    <li>
                        <p>
                            Both you and EveryPast may bring a suit in court in the state of
                            Missouri only for a claim of infringement or other misuse of
                            intellectual property rights. In this case, we both waive any right
                            to a jury trial.
                        </p>
                    </li>
                    <li>
                        <p>
                            If it qualifies, you may bring a claim to the attention of a
                            relevant federal, state, or local agency that may seek relief
                            against us on your behalf.
                        </p>
                    </li>
                </ol>
                <p>
                    If you have a subscription and you terminate for our breach after
                    providing us with a 30-day cure period during which we are unable to
                    cure, we will refund any pre-paid fees on a pro-rated basis to you.
                </p>
                <p>
                    <u>Arbitration Rules</u>: Arbitration will be conducted by a private
                    alternative dispute resolution (ADR) provider in accordance with the ADR
                    provider&rsquo;s arbitration rules, excluding any rules or procedures
                    governing or permitting class actions. The arbitrator, and not any
                    federal, state, or local court or agency, shall have exclusive authority
                    to resolve all disputes arising out of or relating to the
                    interpretation, applicability, enforceability, or formation of these
                    Terms or the <a href="/privacypolicy">Privacy Statement</a>, including but not limited to any claim
                    that all or any part of these Terms or <a href="/privacypolicy">Privacy Statement</a> is void or
                    voidable, whether a claim is subject to arbitration, or the question of
                    waiver by litigation conduct. The arbitrator shall be empowered to grant
                    whatever relief would be available in a court under law or in equity.
                    The arbitrator&rsquo;s award shall be written and shall be binding on
                    the parties and may be entered as a judgment in any court of competent
                    jurisdiction. To start an arbitration, you must do the following: (a)
                    write a Demand for Arbitration that includes a description of the claim
                    and the amount of damages you seek to recover; (b) send three copies of
                    the Demand for Arbitration, plus the appropriate filing fee, to the
                    office of the ADR provider; and (c) send one copy of the Demand for
                    Arbitration to EveryPast at EveryPast Legal Department, 3170 Willow Bend
                    Dr, St Charles, MO 63303. Arbitration may take place in the county where
                    you reside at the time of filing. You and EveryPast further agree to
                    submit to the personal jurisdiction of any federal or state court in
                    Missouri in order to compel arbitration, to stay proceedings pending
                    arbitration, or to confirm, modify, vacate, or enter judgment on the
                    award entered by the arbitrator.
                </p>
                <p>
                    <u>Fees</u>: You will be required to pay $250 to initiate an arbitration
                    against us. You are responsible for your own arbitration fees and your
                    own attorneys&rsquo; fees unless the arbitration rules and/or applicable
                    law provide otherwise.
                </p>
                <p>
                    <u>No Class Actions</u>:{" "}
                    <strong>
                        You and EveryPast each agree that each party may only resolve disputes
                        with the other on an individual basis and may not bring a claim as a
                        plaintiff or a class member in a class, consolidated, or
                        representative action.
                    </strong>{" "}
                    If any court or arbitrator determines that the class action waiver set
                    forth in this paragraph is void or unenforceable for any reason or that
                    an arbitration can proceed on a class basis, then the arbitration
                    provisions set forth above shall be deemed null and void in their
                    entirety and the parties shall be deemed to have not agreed to arbitrate
                    disputes.
                </p>
                <p>
                    <u>Changes to This Section</u>: EveryPast will provide thirty (30)
                    days&rsquo; notice of any changes affecting the substance of this
                    section by posting notice of modifications to the Terms on the Services,
                    sending you a message, or otherwise notifying you when you are logged
                    into your account. Amendments will become effective thirty (30) days
                    after they are posted on the Services or sent to you.
                </p>
                <p>
                    Changes to this section will otherwise apply prospectively only to
                    claims arising after the thirtieth (30th) day. If a court or arbitrator
                    decides that this subsection on &quot;Changes to This Section&quot; is
                    not enforceable or valid, then this subsection shall be severed from the
                    section entitled &ldquo;Dispute, Resolution, Arbitration and Class
                    Action Waiver,&rdquo; and the court or arbitrator shall apply the first
                    Arbitration and Class Action Waiver section in existence after you began
                    using the Services.
                </p>
                <p>
                    <u>Survival</u>: This Dispute Resolution, Arbitration and Class Action
                    Waiver section shall survive any termination of your account or the
                    Services.
                </p>
                <h3>For Customers Outside the U.S.:</h3>
                <p>
                    You agree that the Irish Courts will have exclusive jurisdiction over
                    all disputes (contractual or non-contractual) related to this Agreement.
                    If you are a European Union consumer you will benefit from any mandatory
                    provisions of the law of the country in which you are resident. Nothing
                    in this Agreement, including the paragraph above, affects your rights as
                    a consumer to rely on such mandatory provisions of local law. Nothing
                    contained in this clause shall limit EveryPast&rsquo;s rights to bring
                    enforcement proceedings in another jurisdiction or to seek interim,
                    protective or provisional relief in the courts of another jurisdiction.
                </p>
                <p>
                    <u>Additional Dispute Information for EU Members</u>: Information
                    pursuant to Regulation 524/2013: The European Commission provides a
                    platform for Online Dispute Resolution (ODR), available
                    at&nbsp;http://ec.europa.eu/consumers/odr/.
                </p>
                <p>&nbsp;</p>
                <h2>11. Miscellaneous</h2>
                <p>
                    <u>EveryPast Companies</u>: You are entering into these Terms with a
                    specific EveryPast entity depending on what Service you are using and
                    where you are geographically located. Please see the list of EveryPast
                    entities by Service and geography here. All references to{" "}
                    <strong>
                        &ldquo;EveryPast&rdquo;, &ldquo;us&rdquo;, &ldquo;we&rdquo;, or
                        &ldquo;our&rdquo;
                    </strong>{" "}
                    in these terms refer to the relevant EveryPast entity on that list. We
                    reserve the right to change the EveryPast entity which is a party to
                    these Terms at any time as a result of a corporate reorganization or
                    otherwise.
                </p>
                <p>
                    <u>Modifications to these Terms</u>: We have the right to modify these
                    Terms or any additional terms that apply to a Service at any time,
                    including to reflect changes to the law or changes to our Services. We
                    will notify you of any material changes by posting information through
                    the Services or via email. Such material changes will not apply
                    retroactively and will become effective thirty days after they are
                    posted, except that changes addressing new functions in the Services or
                    changes made for legal reasons will be effective immediately. Except as
                    explicitly provided herein, your continued use of the Services after a
                    change to the Terms will mean you accept the changes. If any changes are
                    unacceptable to you, you may stop using the Services and, if applicable,
                    cancel your subscription as described&nbsp;here.
                </p>
                <p>
                    <u>Entire Agreement</u>: These Terms, including all rules, guidelines,
                    and other documents incorporated herein by reference, state the entire
                    agreement between you and EveryPast regarding your use of the Services
                    and supersede any prior agreements we may have relating to the Services.
                </p>
                <p>
                    <u>Notification of Changes to the Services</u>: EveryPast may contact
                    you within the Services, via email or physical mail to inform you of
                    changes to the Services or these Terms. You agree that contact in any of
                    these ways will satisfy any legal communication requirements, including
                    that communication be in writing.
                </p>
                <p>
                    <u>Feedback</u>: If you submit feedback or suggestions about EveryPast
                    or our Services, we may use your feedback or suggestions for any purpose
                    without any obligation to you.
                </p>
                <p>
                    <u>Assignment</u>: We reserve the right to assign or transfer our rights
                    and obligations under this Agreement. These terms are personal to you
                    and, as a result, you may not, without the written consent of EveryPast,
                    assign or transfer any of your rights and obligations under this
                    Agreement. There shall be no third-party beneficiaries to this
                    Agreement.
                </p>
                <p>
                    <u>If EveryPast is Acquired</u>: If EveryPast or its businesses are
                    acquired or transferred to another entity (in whole or part and
                    including in connection with bankruptcy or similar proceedings),
                    EveryPast has the right to share your Personal Information, User
                    Provided Content with that entity. These Terms will continue to apply to
                    the Services until you receive notification of changes to the Terms or
                    Services.
                </p>
                <p>
                    <u>Severability</u>: Except as explicitly provided herein, the
                    unenforceability of any section or clause in these Terms will not affect
                    the enforceability of the remaining Terms. We may replace any
                    unenforceable section or clause with a similar one that is enforceable.
                </p>
                <p>
                    <u>No Waiver</u>: Our failure to enforce any provision of these Terms is
                    not a waiver of our rights under that provision.
                </p>
                <p>
                    <u>Fair Credit Reporting Act</u>: EveryPast is not a consumer reporting
                    agency as defined in the Fair Credit Reporting Act (&ldquo;
                    <strong>FCRA</strong>&rdquo;), and the information that you can access
                    on the Services has not been collected in whole or in part for the
                    purpose of furnishing consumer reports, as defined in the FCRA. YOU
                    SHALL NOT USE THE SERVICES AS A FACTOR IN (1) ESTABLISHING AN
                    INDIVIDUAL&apos;S ELIGIBILITY FOR PERSONAL CREDIT OR INSURANCE OR
                    ASSESSING RISKS ASSOCIATED WITH EXISTING CONSUMER CREDIT OBLIGATIONS,
                    (2) EVALUATING AN INDIVIDUAL FOR EMPLOYMENT, PROMOTION, REASSIGNMENT OR
                    RETENTION (INCLUDING BUT NOT LIMITED TO EMPLOYMENT OF HOUSEHOLD WORKERS
                    SUCH AS BABYSITTERS, CLEANING PERSONNEL, NANNIES, CONTRACTORS, AND OTHER
                    INDIVIDUALS), OR (3) ANY OTHER PERSONAL BUSINESS TRANSACTION WITH
                    ANOTHER INDIVIDUAL (INCLUDING, BUT NOT LIMITED TO, LEASING AN
                    APARTMENT).
                </p>
                <p>&nbsp;</p>
            </div>
        </div>
    );
};

export default TermsPage;