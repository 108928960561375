import React, { useEffect, useState } from "react";
import QuestionBlock from "../components/questionBlock/QuestionBlock";
import QuestionGrid from "../components/questionGrid/QuestionGrid";

import { Link, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import Box from "@material-ui/core/Box";
import styles from "./SecondaryUserProfilePage.module.scss";
import "./SecondaryUserProfilePageAppbar.scss";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";
import Modal from "@material-ui/core/Modal";
import TextField from "@material-ui/core/TextField";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

import MuiAlert from "@material-ui/lab/Alert";
import Switch from "@material-ui/core/Switch";
import { FormGroup } from "@material-ui/core";
import { FormControlLabel } from "@material-ui/core";

import { removeResponse, showLoader, hideLoader } from "../actions/auth";

import QuestionAnswerBlock from "../components/questionAnswerBlock/QuestionAnswerBlock";

import { useDispatch, useSelector } from "react-redux";

import {
  getSecondaryUserQuestions,
  getSecondaryUserRandomQuestion,
  getSecondaryUserCategoryQuestions,
  getCategories,
} from "../actions/questions";

import {
  getSecondaryUser,
  updateSecondaryUserName,
  updateSecondaryUserBio,
  updateSecondaryUserPrivacy,
  updateSecondaryUserBday,
  createSecondaryUserAnswer,
} from "../actions/user";

import ReactPaginate from "react-paginate";
import styled from "styled-components";
import classes from "./HomePage.module.scss";

// You can style your pagination component
// thanks to styled-components.
// Use inner class names to style the controls.
const MyPaginate = styled(ReactPaginate).attrs({
  // You can redifine classes here, if you want.
  activeClassName: "active", // default to "disabled"
})`
  display: flex;
  flex-basis: fit-content;
  max-width: 400px;
  justify-content: space-between;
  padding: 0px;
  margin: 30px auto;
  list-style-type: none;
  li {
    transition: all 0.1s ease-in-out;
  }
  li a {
    border-radius: 7px;
    padding: 0.1rem 1rem;
    // border: gray 1px solid;
    cursor: pointer;
  }
  li.previous a,
  li.next a,
  li.break a {
    border-color: transparent;
  }
  li:hover {
    transform: scale(1.2);
  }
  li.active a {
    background-color: #4e9a9b;
    border-color: transparent;
    color: white;
    // min-width: 32px;
  }
  li.disabled a {
    color: grey;
  }
  li.disable,
  li.disabled a {
    cursor: default;
  }
`;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

// backgroundColor: theme.palette.background.paper,
// STYLE FOR TABS
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  text: {
    color: "green",
  },
  indicatorColor: {
    color: "green",
    backgroundColor: "green",
    borderColor: "green",
  },
}));

// STYLE FOR MODAL
const useDyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "90%",
    },
  },
  paper: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: theme.palette.background.paper,
    border: "none",
  },
  paperBio: {
    position: "absolute",
    width: "70%",
    maxWidth: "600px",
    backgroundColor: theme.palette.background.paper,
    border: "none",
    borderRadius: "15px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  innerContainer: {
    position: "relative",
    textAlign: "center",
    width: "50%",
    height: "70%",
    border: "none",
  },
}));

// MAIN FUNCTION
export default function SimpleTabs() {
  const classes = useStyles();
  const dyles = useDyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = useState(getModalStyle);

  const dispatch = useDispatch();

  const auth = useSelector((state) => state.auth);
  const user = useSelector((state) => state.secondaryUser);

  const questions = useSelector((state) => state.secondaryQuestions);
  const categoryList = useSelector((state) => state.categories);

  const randomQuestion = useSelector((state) => state.random);
  const [category, setCategory] = useState("");

  // We start with an empty list of items.
  const [currentItemArray, setCurrentItemArray] = useState([]);
  const [currentItems, setCurrentItems] = useState(null);

  // page count for pagination
  const [pageCount, setPageCount] = useState(0);

  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 6;

  const loading = useSelector((state) => state.auth.compLoad);
  const [answerData, setAnswerData] = useState("");

  // ID USED TO CALL GET SECONDARY USER
  let { state } = useLocation();

  const [value, setValue] = React.useState(0);
  const [open, setOpen] = useState(false);
  const [openBioModal, setOpenBioModal] = useState(false);
  const [openRandomModal, setOpenRandomModal] = React.useState(false);

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [response, setResponse] = useState("");
  const [name, setName] = useState({
    first_name: state.first_name,
    last_name: state.last_name,
  });
  const [bio, setBio] = useState({
    bio: user.bio,
  });
  const publicValue = useSelector((state) => state.secondaryUser.public);
  const [isPublic, setisPublic] = useState(publicValue);

  const [bday, setBday] = useState({
    bday: "",
  });

  function checkValue(str, max) {
    if (str.charAt(0) !== "0" || str == "00") {
      var num = parseInt(str);
      if (isNaN(num) || num <= 0 || num > max) num = 1;
      str =
        num > parseInt(max.toString().charAt(0)) && num.toString().length == 1
          ? "0" + num
          : num.toString();
    }
    return str;
  }

  const handleCatChange = (event) => {
    dispatch(showLoader());
    setCategory(event.target.value);
    // console.log("THIS IS CAT:" + event.target.value);
    // send getQuestions with the category string
    dispatch(getSecondaryUserCategoryQuestions(state.id, event.target.value));
    // return all questions of that category
    // set currentItemsArray with those questions
    setCurrentItemArray(questions);
    // Then, that should update currentItems
    setTimeout(function () {
      // dispatch(getCategoryQuestions(category));
      // setCurrentItemArray(questions);
      dispatch(hideLoader());
    }, 700);
  };

  const handleBlur = (e) => {
    e.target.type = "text";
    var input = e.target.value;
    var values = input.split("/").map(function (v, i) {
      return v.replace(/\D/g, "");
    });
    var output = "";

    if (values.length == 3) {
      var year =
        values[2].length !== 4
          ? parseInt(values[2]) + 2000
          : parseInt(values[2]);
      var month = parseInt(values[0]) - 1;
      var day = parseInt(values[1]);
      var d = new Date(year, month, day);
      // if (!isNaN(d)) {
      //   document.getElementById("result").innerText = d.toString();
      //   var dates = [d.getMonth() + 1, d.getDate(), d.getFullYear()];
      //   output = dates
      //     .map(function (v) {
      //       v = v.toString();
      //       return v.length == 1 ? "0" + v : v;
      //     })
      //     .join(" / ");
      // }
    }
    e.target.value = output;
  };

  const handleInput = (e) => {
    e.target.type = "text";
    var input = e.target.value;
    // console.log(input);
    if (/\D\/$/.test(input)) input = input.substr(0, input.length - 3);
    var values = input.split("/").map(function (v) {
      return v.replace(/\D/g, "");
    });
    if (values[0]) values[0] = checkValue(values[0], 12);
    if (values[1]) values[1] = checkValue(values[1], 31);
    var output = values.map(function (v, i) {
      return v.length == 2 && i < 2 ? v + " / " : v;
    });
    e.target.value = output.join("").substr(0, 14);
  };

  // Open Name Modal
  const handleOpen = () => {
    setOpen(true);
  };

  // Open Bio Modal
  const handleOpenBioModal = () => {
    setOpenBioModal(true);
  };

  // Open Random Modal
  const handleOpenRandomModal = () => {
    setOpenRandomModal(true);
  };

  // Close All Modals
  const handleClose = () => {
    setOpen(false);
    setOpenBioModal(false);
    setOpenRandomModal(false);
    setIsSubmitted(false);
    setAnswerData("");
  };

  //  Switch to Public or Private Profile
  const handleChecked = () => {
    dispatch(updateSecondaryUserPrivacy(state.id));
    setResponse(auth.response);
    setisPublic(!isPublic);
    setTimeout(function () {
      dispatch(removeResponse());
    }, 3000);
  };

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    // setCurrentItems depends on the currentItemArray
    // Could be normal questions, or category
    setCurrentItems(currentItemArray.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(currentItemArray.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, currentItemArray]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % currentItemArray.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  useEffect(() => {
    // dispatch(getQuestions());
    // dispatch(getCategoryQuestions(category));

    if (category) {
      setCurrentItemArray(questions);
      // dispatch(getCategoryQuestions(category));
    } else {
      setCurrentItemArray(questions);
    }
  }, [dispatch, questions, category]);

  useEffect(() => {
    dispatch(showLoader());
    dispatch(getCategories());
    dispatch(getSecondaryUserRandomQuestion(state.id));

    dispatch(getSecondaryUserQuestions(state.id));
    setCurrentItemArray(questions);
    setTimeout(function () {
      dispatch(removeResponse());
    }, 3000);
    setTimeout(function () {
      dispatch(hideLoader());
    }, 700);
    // setName({
    //   first_name: user.first_name,
    //   last_name: user.last_name,
    // });
  }, []);

  useEffect(() => {
    setResponse(auth.response);
    dispatch(getSecondaryUser(state.id));
    // dispatch(getSecondaryUserQuestions(state.id));
    setisPublic(publicValue);
  }, [dispatch, state.id, publicValue, auth.response]);
  // auth.response was removed

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Submit for Random Question
  const handleSubmit = (e) => {
    // once user submits, we want to send over a post request with all the data they typed in
    e.preventDefault();
    dispatch(
      createSecondaryUserAnswer(state.id, answerData, randomQuestion.id)
    );
    dispatch(getSecondaryUserRandomQuestion(state.id));
    // When we submit, we want to remove this question form the currentItemArray

    setIsSubmitted(true);
    setAnswerData("");
  };

  const handleSubmitName = (e) => {
    // once user submits, we want to send over a post request with all the data they typed in
    e.preventDefault();

    dispatch(updateSecondaryUserName(state.id, name));
    dispatch(updateSecondaryUserBday(state.id, bday));
    setIsSubmitted(true);
    // setName({
    //   first_name: "",
    //   last_name: "",
    // });
    // setBday({
    //   bday: "",
    // });
  };

  const handleSubmitBio = (e) => {
    // once user submits, we want to send over a post request with all the data they typed in
    e.preventDefault();
    dispatch(updateSecondaryUserBio(state.id, bio));
    setIsSubmitted(true);
    setBio({
      bio: "",
    });
  };

  // Name Modal Body
  const body = (
    <div style={modalStyle} className={dyles.paperBio}>
      <p id='simple-modal-description' className={classes.modalQuestion}>
        {null}
      </p>
      <form className={dyles.root} noValidate autoComplete='off'>
        <TextField
          id='first_name'
          label='First Name'
          // multiline
          rows={1}
          //   defaultValue={name.first_name}
          variant='outlined'
          value={name.first_name}
          onChange={(e) => setName({ ...name, first_name: e.target.value })}
        />
        <TextField
          id='last_name'
          label='Last Name'
          // multiline
          rows={1}
          //   defaultValue={name.last_name}
          variant='outlined'
          value={name.last_name}
          onChange={(e) => setName({ ...name, last_name: e.target.value })}
        />
        <TextField
          id='bday'
          label='Date of Birth (MM/DD/YYYY)'
          // multiline
          rows={1}
          defaultValue=''
          variant='outlined'
          //   value={user.bday}
          onInput={handleInput}
          onBlur={handleBlur}
          onChange={(e) => setBday({ bday: e.target.value })}
        />
      </form>
      <button
        className={styles.submitButton}
        type='submit'
        onClick={handleSubmitName}
      >
        Submit
      </button>
      <button className={styles.cancelButton} onClick={handleClose}>
        Cancel
      </button>
    </div>
  );

  const bodyBio = (
    <div style={modalStyle} className={dyles.paperBio}>
      <form className={dyles.root} noValidate autoComplete='off'>
        <TextField
          id='bio'
          label='Biography'
          multiline
          rows={8}
          defaultValue={user.bio}
          variant='outlined'
          // value={name.first_name}
          onChange={(e) => setBio({ bio: e.target.value })}
        />
      </form>
      <button
        className={styles.submitButton}
        type='submit'
        onClick={handleSubmitBio}
      >
        Submit
      </button>
      <button className={styles.cancelButton} onClick={handleClose}>
        Cancel
      </button>
      <p style={{ fontSize: "10px" }}>
        All submissions must abide by the{" "}
        <a href='/terms' target='_blank'>
          Terms and Conditions
        </a>
        , which you accepted.
      </p>
    </div>
  );

  const wordsInAnswer = answerData
    ? answerData
        .trim()
        .split(" ")
        .filter((w) => w != "").length
    : 0;
  const wordCount = wordsInAnswer === 1 ? "1 word" : `${wordsInAnswer} words`;
  const wordGoal = 500;
  const wordsToGoal = wordGoal - wordsInAnswer;

  const bodyRandom = (
    <div style={modalStyle} className={dyles.paper}>
      <div style={modalStyle} className={dyles.innerContainer}>
        <div id='simple-modal-description' className={dyles.modalQuestion}>
          <div style={{ display: "table" }}>
            <div style={{ display: "table-row" }}>
              <div style={{ display: "table-cell", verticalAlign: "middle" }}>
                <div
                  style={{
                    background: "#e9f2f7",
                    padding: "20px",
                    borderRadius: "20px",
                    display: "table-cell",
                  }}
                >
                  {randomQuestion.question}
                </div>
              </div>
              <div style={{ display: "table-cell", verticalAlign: "middle" }}>
                <div
                  style={{
                    display: "inline-block",
                    alignItems: "center",
                    borderTop: "0px solid transparent",
                    borderLeft: "25px solid #e9f2f7",
                    borderBottom: "25px solid transparent",
                  }}
                ></div>
              </div>
              <div style={{ display: "table-cell", verticalAlign: "middle" }}>
                <img
                  src='https://hansschmidt.org/wp-content/uploads/2021/11/Eppy-thinking-pose.png'
                  style={{ verticalAlign: "middle" }}
                  width='100'
                  height='132'
                ></img>
              </div>
            </div>
          </div>
        </div>
        <form className={dyles.root} noValidate autoComplete='off'>
          <TextField
            id='outlined-multiline-static'
            label='Answer'
            multiline
            rows={8}
            // defaultValue=''
            variant='outlined'
            value={answerData}
            onChange={(e) => setAnswerData(e.target.value)}
          />
          {wordsInAnswer < 250 ? (
            <p style={{ margin: "5px 0px 5px 10px" }}>
              You've typed {wordCount}. Can you dig deeper?
            </p>
          ) : (
            <div>
              {wordsInAnswer < 500 ? (
                <p style={{ margin: "5px 0px 5px 10px" }}>
                  You've typed {wordCount}! Tell me more!
                </p>
              ) : (
                <p style={{ margin: "5px 0px 5px 10px" }}>
                  You've typed {wordCount}! You have a great memory!
                </p>
              )}
            </div>
          )}
        </form>
        <button
          className={styles.submitButton}
          type='submit'
          onClick={handleSubmit}
        >
          Submit
        </button>
        <button className={styles.cancelButton} onClick={handleClose}>
          Cancel
        </button>
        <p style={{ fontSize: "10px" }}>
          All submissions must abide by the{" "}
          <a href='/terms' target='_blank'>
            Terms and Conditions
          </a>
          , which you accepted.
        </p>
      </div>
    </div>
  );

  const bodyRandomSubmitted = (
    <div style={modalStyle} className={dyles.paper}>
      <div className={styles.thanksContainer}>
        <h1>Thank you!</h1>
        {window.location.href.indexOf("edit") > -1 ? (
          <>
            <h2>
              You can view and update responses any time in the "Answers" tab
            </h2>
            <button className={styles.viewInProfile} onClick={handleClose}>
              Close
            </button>
          </>
        ) : (
          <>
            <h2>You can view and update responses any time in your profile</h2>
            <Link to='/profile'>
              <button className={styles.viewInProfile}>
                View in Profile{" "}
                <ChevronRightIcon className={styles.chevRight} />
              </button>
            </Link>
          </>
        )}
      </div>
    </div>
  );

  const bodySubmitted = (
    <div style={modalStyle} className={dyles.paperBio}>
      <div className={styles.thanksContainer}>
        <h1>Successfully Updated</h1>
        <button className={styles.viewInProfile} onClick={handleClose}>
          Close
        </button>
      </div>
    </div>
  );

  const paginate = (
    <MyPaginate
      breakLabel='..'
      nextLabel='>'
      onPageChange={handlePageClick}
      pageRangeDisplayed={2}
      marginPagesDisplayed={1}
      pageCount={pageCount}
      previousLabel='<'
      pageClassName='page-item'
      pageLinkClassName='page-link'
      previousClassName='page-item'
      previousLinkClassName='page-link'
      nextClassName='page-item'
      nextLinkClassName='page-link'
      breakClassName='page-item'
      breakLinkClassName='page-link'
      containerClassName='pagination'
      activeClassName='active'
      renderOnZeroPageCount={null}
    />
  );

  const questionSection = () => {
    return (
      <>
        <div className={styles.rulesContainer}>
          {/* SET THE RESPONSE */}
          {auth.response && (
            <Alert
              style={{ marginBottom: "20px" }}
              severity='success'
              onClick={() => {
                dispatch({ type: "REMOVE_RESPONSE" });
              }}
            >
              {auth.response || response}
            </Alert>
          )}
          <h1 className={styles.rules}>
            Start answering questions to have them added to your profile!
          </h1>
          <h1 className={styles.rules2}>
            Choose a category, or let Eppy choose a random question for you!
          </h1>
        </div>

        {/* CATEGORIES */}
        <div className={styles.topButtons}>
          <FormControl style={{ minWidth: "120px" }}>
            <InputLabel id='CategorySelect'>Categories</InputLabel>

            <Select
              labelId='SelectLabelId'
              id='SelectId'
              value={category}
              onChange={handleCatChange}
              label='Categories'
            >
              <MenuItem value=''></MenuItem>
              {categoryList.map((val) => {
                return <MenuItem value={val}>{val}</MenuItem>;
              })}
            </Select>
          </FormControl>

          <button
            className={styles.randomButton}
            onClick={handleOpenRandomModal}
          >
            Let Eppy Pick
          </button>

          <img
            src='https://hansschmidt.org/wp-content/uploads/2021/11/Eppy-waving-pose-mirrored.png'
            width='83'
            height='100'
            alt='Let Eppy Pick'
          ></img>
        </div>

        {/* THIS IS THE PAGINATION */}

        {paginate}

        {/* THIS IS THE QUESTION LIST GRID */}

        <Grid container>
          {currentItems &&
            currentItems.map((question) => {
              //   console.log("IN MAIN IF");
              //   console.log("category is: " + category);
              //   console.log(question.category.category);
              return (
                <Grid key={question.id} item xs={12} sm={6} md={4}>
                  <QuestionBlock
                    currentItemArray={currentItemArray}
                    setCurrentItemArray={setCurrentItemArray}
                    currentItems={currentItems}
                    text={question.question}
                    id={question.id}
                    userID={state.id}
                  />
                </Grid>
              );
            })}
        </Grid>

        {/* <QuestionGrid
          currentItemArray={currentItemArray}
          setCurrentItemArray={setCurrentItemArray}
          currentItems={currentItems}
          loading={loading}
          userID={state.id}
        /> */}
      </>
    );
  };

  const answerSection = () => {
    if (!user.answers) {
      return null;
    } else {
      return (
        <>
          {user.answers.map((answer, index) => {
            return (
              <QuestionAnswerBlock
                key={index}
                text={answer.answer}
                match={index}
                id={answer.id}
                userID={state.id}
              />
            );
          })}
        </>
      );
    }
  };

  // const test = !user.questions ? null : user.questions[0].question;

  return (
    <>
      <div className={styles.container}>
        <div className={styles.padding}>
          {/* SET THE RESPONSE */}
          {response && (
            <Alert
              style={{ marginBottom: "20px" }}
              className={dyles.alert}
              severity='success'
              onClick={() => {
                setResponse(null);
                dispatch({ type: "ADD_RESPONSE", payload: response });
              }}
            >
              {auth.response || response}
            </Alert>
          )}
          <h4>Sub-Profile</h4>
          <h2>
            <span>Editing&nbsp;</span>
            {user.first_name} <span>{user.last_name}'s&nbsp;</span>
            <span>Profile</span>
          </h2>
          <div className={styles.textFlex}>
            <Link to={`/secondary/${state.id}`}>
              <button>
                View Profile
                <VisibilityIcon className={styles.eye} />
              </button>
            </Link>

            <h3>Profile View:</h3>
            <FormGroup className={styles.switch}>
              <FormControlLabel
                className={styles.switchText}
                control={
                  <Switch
                    defaultValue={isPublic}
                    onChange={handleChecked}
                    // checked={isPublic}
                    // onChange={(e) => setisPublic((prevCheck) => !prevCheck)}
                    // onChange={() => handleChecked()}
                  />
                }
                label={isPublic ? "Currently Public" : "Currently Private"}
              />
            </FormGroup>
          </div>
        </div>
      </div>
      <div className={classes.root}>
        <AppBar position='static' style={{ background: "#333" }}>
          <Tabs
            centered
            value={value}
            onChange={handleChange}
            aria-label='simple tabs example'
            indicatorColor='primary'
          >
            <Tab label='Edit Info' {...a11yProps(0)} />
            <Tab label='Questions' {...a11yProps(1)} />
            <Tab label='Saved Answers' {...a11yProps(2)} disabled={loading} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0} className={styles.tabs}>
          <div className={styles.containerInfo}>
            <div className={styles.padding}>
              <div className={styles.textBox}>
                <div className={styles.textFlex}>
                  <h3>First Name:</h3>
                  <h2>{user.first_name}</h2>
                </div>

                <div className={styles.textFlex}>
                  <h3>Last Name:</h3>
                  <h2>{user.last_name}</h2>
                </div>

                <div className={styles.textFlex}>
                  <h3>Birth Date:</h3>
                  <h2>
                    {user.bday.substring(5, 7)}/{user.bday.substring(8, 10)}/
                    {user.bday.substring(0, 4)}
                  </h2>
                </div>
              </div>

              <div className={styles.edit} onClick={handleOpen}>
                EDIT <EditIcon className={styles.editIcon} />
              </div>
            </div>
          </div>
          <div className={styles.containerInfo}>
            <div className={styles.padding}>
              <h3>Attention</h3>
              <span>
                Before you interview this individual, we require that you
                <div>
                  (1) understand that you are not an employee or representative
                  of EveryPast and promise not to hold yourself out as such;
                </div>
                <div>
                  (2) promise to treat your interviewee respectfully, and, when
                  you record their answers, you avoid stereotypes,
                  misrepresentations, and manipulations of their words;
                </div>
                <div>
                  (3) give your interviewee a way to get in contact with you, in
                  case they want to change, remove, or privatize their answers
                  or other information; and
                </div>
                <div>
                  (4) read aloud to them, or show them, the following text, and
                  confirm that they understand it:
                </div>
              </span>
              <div style={{ height: "10px" }}></div>
              <div
                style={{
                  background: "#e9f2f7",
                  padding: "20px",
                  borderRadius: "20px",
                }}
              >
                <span>
                  <span style={{ fontSize: "30px", fontWeight: "bold" }}>
                    ‘‘
                  </span>
                  <ul>
                    <li>
                      Your answers will help others understand your personal,
                      family, and local histories, and the world history that
                      you have been a part of.
                    </li>
                    <li>
                      Your answers will be publicly available at EveryPast.com.
                    </li>
                    <li>
                      You can ask me to make your answers private, to change any
                      of your answers, or to delete any of your answers,
                      whenever you want, even after this interview is over.
                    </li>
                    <li>You only have to answer a question if you want to.</li>
                    <li>You can end the interview whenever you want.</li>
                  </ul>
                  <div
                    style={{
                      textAlign: "right",
                      fontSize: "30px",
                      fontWeight: "bold",
                    }}
                  >
                    ’’
                  </div>
                </span>
              </div>
            </div>
          </div>
          <div className={styles.containerInfo}>
            <div className={styles.padding}>
              <h3>Biography</h3>
              <span>
                (Use this space to record any memories and stories you like,
                even if they are not a direct answer to one of our question
                cards.)
              </span>
              <div style={{ height: "10px" }}></div>
              <div
                style={{
                  background: "#e9f2f7",
                  padding: "20px",
                  borderRadius: "20px",
                }}
              >
                <div style={{ whiteSpace: "pre-line" }}>{user.bio}</div>
              </div>
              <div style={{ height: "35px" }}></div>
              <div className={styles.edit} onClick={handleOpenBioModal}>
                EDIT <EditIcon className={styles.editIcon} />
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={value} index={1} className={styles.tabs}>
          {questionSection()}
        </TabPanel>
        <TabPanel value={value} index={2} className={styles.tabs}>
          {answerSection()}
        </TabPanel>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-describedby='simple-modal-description'
      >
        {isSubmitted ? bodySubmitted : body}
      </Modal>

      <Modal
        open={openBioModal}
        onClose={handleClose}
        aria-describedby='simple-modal-description'
      >
        {isSubmitted ? bodySubmitted : bodyBio}
      </Modal>

      <Modal
        open={openRandomModal}
        onClose={handleClose}
        aria-describedby='simple-modal-description'
      >
        {isSubmitted ? bodyRandomSubmitted : bodyRandom}
      </Modal>
    </>
  );
}
