export default (
  auth = {
    token: localStorage.getItem("token"),
    id: localStorage.getItem("id"),
    email: localStorage.getItem("email"),
    first_name: localStorage.getItem("first_name"),
    last_name: localStorage.getItem("last_name"),
    isAuthenticated: null,
    isLoading: false,
    compLoad: false,
    // user: null,
    sentValidate: false,
    verificationResponse: "",
  },
  action
) => {
  switch (action.type) {
    // We get a response back from the backend, has data in payload.. what do we do with the data?
    case "USER_LOADING":
      return { ...auth, isLoading: true };
    case "LOADING":
      return { ...auth, compLoad: true };
    case "FINISH_LOADING":
      return { ...auth, compLoad: false };
    case "USER_LOADED":
      return {
        ...auth,
        isAuthenticated: true,
        isLoading: false,
        // user: action.payload,
      };
    case "LOGIN_SUCCESS":
      localStorage.setItem("token", action.payload.token);
      localStorage.setItem("id", action.payload.id);
      localStorage.setItem("email", action.payload.email);
      localStorage.setItem("first_name", action.payload.first_name);
      localStorage.setItem("last_name", action.payload.last_name);
      // localStorage.setItem("bio", action.payload.bio);
      return {
        ...auth,
        ...action.payload,
        isAuthenticated: true,
        isLoading: false,
      };
    case "FIRST_LOGIN":
      localStorage.setItem("token", action.payload.token);
      return {
        ...auth,
        isAuthenticated: true,
        isLoading: false,
      };
    case "ADD_ERROR":
      // console.log("THE RESPOONSSEEE");
      // console.table(action.payload);
      return {
        ...auth,
        response: action.payload,
      };
    case "ADD_ERROR_CODE":
      // console.table(action.payload);
      return {
        ...auth,
        response_code: action.payload,
      };
    case "ADD_RESPONSE":
      // console.log("THE RESPOONSSEEE");
      // console.table(action.payload);
      return {
        ...auth,
        response: action.payload.response,
      };

    case "REMOVE_RESPONSE":
      return {
        ...auth,
        response: "",
      };

    case "UPDATE_AUTH_NAME":
      localStorage.setItem("first_name", action.payload.first_name);
      localStorage.setItem("last_name", action.payload.last_name);
      return {
        ...auth,
        first_name: action.payload.first_name,
        last_name: action.payload.last_name,
      };

    case "UPDATE_AUTH_EMAIL":
      localStorage.setItem("email", action.payload.email);
      return {
        ...auth,
        email: action.payload.email,
      };

    case "SENT_VALIDATE":
      return {
        ...auth,
        sentValidate: true,
      };

    case "REMOVE_VALIDATE":
      return {
        ...auth,
        sentValidate: false,
      };

    case "VERIFY_SUCCESS":
      return {
        ...auth,
        verificationResponse: action.payload.response,
      };

    case "VERIFY_FAIL":
      return {
        ...auth,
        verificationResponse: "Failed to authenticate",
      };

    case "AUTH_ERROR":
    case "LOGIN_FAIL":
    case "LOGOUT_SUCCESS":
    case "DELETE_PROFILE":
      localStorage.removeItem("token");
      localStorage.removeItem("id");
      localStorage.removeItem("email");
      localStorage.removeItem("first_name");
      localStorage.removeItem("last_name");
      return {
        ...auth,
        token: null,
        isAuthenticated: false,
        isLoading: false,
        id: "",
        email: "",
        first_name: "",
        last_name: "",
        bio: "",
      };

    default:
      return auth;
  }
};
