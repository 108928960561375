import React from "react";

import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton,
    TwitterIcon,
    TwitterShareButton
} from "react-share";

export default function ShareAndReport() {
    return (

    <p align="right">
        Share:
        <br/>
        <FacebookShareButton url={window.location.href} quote='Check out this life story on EveryPast:'><FacebookIcon
            size={32} round={true}/></FacebookShareButton>
        <TwitterShareButton url={window.location.href} title='Check out this life story on EveryPast:'><TwitterIcon
            size={32} round={true}/></TwitterShareButton>
        <EmailShareButton url={window.location.href} subject='Check out this life story on EveryPast!'><EmailIcon
            size={32} round={true}/></EmailShareButton>
        <br/>
        <a style={{color: `#000000`, fontSize: `15px`}}
           href="javascript:window.location='mailto:info@everypast.com?subject=Reporting a Profile&body=I believe this profile violates the EveryPast terms and conditions because ___________________________________________: ' + window.location;">
            Report This Profile
        </a>
    </p>

);
}