import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { store } from "../../index";

import CircularProgress from "@material-ui/core/CircularProgress";

import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Modal from "@material-ui/core/Modal";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";

import styles from "./QuestionAnswerBlock.module.scss";

import { updateAnswer, removeAnswer } from "../../actions/answers";
import {
  getUser,
  getSecondaryUser,
  updateSecondaryUserAnswer,
  removeSecondaryUserAnswer,
} from "../../actions/user";
import { showLoader, hideLoader } from "../../actions/auth";
import { getSecondaryUserQuestions } from "../../actions/questions";
import classes from "../questionBlock/QuestionBlock.module.scss";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

// STYLE FOR MODAL
const useDyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "90%",
    },
  },
  paper: {
    position: "absolute",
    width: "70%",
    maxWidth: "600px",
    backgroundColor: theme.palette.background.paper,
    border: "none",
    borderRadius: "15px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const QuestionAnswerBlock = ({ text, match, id, userID }) => {
  const dyles = useDyles();
  const dispatch = useDispatch();

  const auth = store.getState().auth;

  const loading = useSelector((state) => state.auth.compLoad);

  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = useState(getModalStyle);

  const [open, setOpen] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [answer, setAnswer] = useState({
    answer: text,
  });

  const [changeRender, setChangeRender] = useState(0);

  // Open Modal
  const handleOpen = () => {
    setOpen(true);
  };

  // Close Modal
  const handleClose = () => {
    setOpen(false);
    setIsSubmitted(false);
    // setChangeRender(changeRender + 1);
  };

  const handleSubmit = (e) => {
    // once user submits, we want to send over a post request with all the data they typed in
    e.preventDefault();
    dispatch(showLoader());

    setIsSubmitted(true);
    if (window.location.href.indexOf("edit") > -1) {
      dispatch(updateSecondaryUserAnswer(answer, id));
      setTimeout(function () {
        dispatch(getSecondaryUser(userID));
      }, 200);
      setTimeout(function () {
        dispatch(getSecondaryUserQuestions(userID));
      }, 400);
      setTimeout(function () {
        dispatch(hideLoader());
      }, 1000);
    } else {
      dispatch(updateAnswer(answer, id));
      setTimeout(function () {
        dispatch(getUser(auth.id));
      }, 400);
      setTimeout(function () {
        dispatch(hideLoader());
      }, 700);
    }
  };

  const handleDelete = (e) => {
    var result = window.confirm(
        "Are you sure you want to delete this question?"
    );
    if (result) {
      dispatch(showLoader());

      if (window.location.href.indexOf("edit") > -1) {
        dispatch(removeSecondaryUserAnswer(id));
        setTimeout(function () {
          dispatch(getSecondaryUser(userID));
        }, 300);
        setTimeout(function () {
          dispatch(getSecondaryUserQuestions(userID));
        }, 300);

        setTimeout(function () {
          dispatch(hideLoader());
        }, 1000);
      } else {
        //Logic to delete the item
        dispatch(removeAnswer(id));
        setTimeout(function () {
          dispatch(getUser(auth.id));
        }, 300);
        setTimeout(function () {
          dispatch(hideLoader());
        }, 700);
      }
    }
  };

  const body = (
      <div style={modalStyle} className={dyles.paper}>
        <form className={dyles.root} noValidate autoComplete='off'>
          <TextField
              id='answer'
              label='Answer'
              multiline
              rows={8}
              defaultValue={answer.answer}
              variant='outlined'
              // value={name.first_name}
              onChange={(e) => setAnswer({ answer: e.target.value })}
          />
        </form>
        <button
            className={classes.submitButton}
            type='submit'
            onClick={handleSubmit}
        >
          Submit
        </button>
        <button className={classes.cancelButton} onClick={handleClose}>
          Cancel
        </button>
        <p style={{fontSize: "10px"}}>
          All submissions must abide by the{" "}
          <a href='/terms' target='_blank'>
            Terms and Conditions
          </a>
          , which you accepted.
        </p>
      </div>
  );

  const bodySubmitted = <h1>Successfully Updated!</h1>;

  //   useEffect(() => {
  //     dispatch(getUser(auth.id));
  //   }, [dispatch, isSubmitted, open, changeRender, auth.id]);

  if (!loading) {
    if (window.location.href.indexOf("edit") > -1) {
      return (
          <>
            <div
                className={styles.containerQuestions}
                style={{
                  borderTop: userID ? "#525ac2 solid 5px" : "#4e9a9b solid 5px",
                }}
            >
              <div className={styles.padding}>
                <h3>
                  {store.getState().secondaryUser.questions[match].question}
                </h3>{" "}
                <h2>{text}</h2>
                <div className={styles.edit} onClick={handleOpen}>
                  EDIT <EditIcon className={styles.editIcon} />
                </div>
                <div className={styles.delete} onClick={handleDelete}>
                  REMOVE <DeleteIcon className={styles.editIcon} />
                </div>
              </div>
            </div>

            <Modal
                open={open}
                onClose={handleClose}
                aria-describedby='simple-modal-description'
            >
              {isSubmitted ? bodySubmitted : body}
            </Modal>
          </>
      );
    } else {
      return (
          <>
            <div className={styles.containerQuestions}>
              <div className={styles.padding}>
                <h3>{store.getState().user.questions[match].question}</h3>
                <h2>{text}</h2>
                <div className={styles.edit} onClick={handleOpen}>
                  EDIT <EditIcon className={styles.editIcon} />
                </div>
                <div className={styles.delete} onClick={handleDelete}>
                  REMOVE <DeleteIcon className={styles.editIcon} />
                </div>
              </div>
            </div>

            <Modal
                open={open}
                onClose={handleClose}
                aria-describedby='simple-modal-description'
            >
              {isSubmitted ? bodySubmitted : body}
            </Modal>
          </>
      );
    }
  } else {
    return (
        <CircularProgress
            style={{
              position: "fixed",
              right: "50%",
              top: "50%",
            }}
        ></CircularProgress>
    );
  }
};

export default QuestionAnswerBlock;
