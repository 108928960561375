import axios from "axios";

const url = "https://everypast.com/api/";
// const url = "http://127.0.0.1:8000/api/";

// There are the API calls... exported to actions

export const fetchAccount = (config) =>
  axios.get(url + "user/account/", config);

export const postAnswer = (newAnswer, id, secUserID, config) =>
  axios.post(url + `question/${id}/`, newAnswer, secUserID, config);

export const putName = (updatedName, config) =>
  axios.put(url + "user/rename/", updatedName, config);

export const putBio = (updatedBio, config) =>
  axios.put(url + "user/bio/", updatedBio, config);

export const putBday = (bday, config) =>
  axios.put(url + "user/bday/", bday, config);

export const putPrivacy = (bool, config) =>
  axios.put(url + "user/privacy/", bool, config);

export const putEmail = (email, config) =>
  axios.put(url + "user/email/", email, config);

export const putPassword = (password, config) =>
  axios.put(url + "user/password/", password, config);

export const putAnswer = (updatedAnswer, id, config) =>
  axios.put(url + `answer/${id}/`, updatedAnswer, config);

export const deleteAnswer = (id, config) =>
  axios.delete(url + `answer/${id}/`, config);

export const deleteProfile = (config) =>
  axios.delete(url + "user/delete/", config);

export const createUser = (userData, config) =>
  axios.post(url + "signup/", userData, config);
// export const loadUser = () => axios.get(url + "")

export const fetchVerification = (id, config) =>
  axios.get(url + `verify/${id}`, config);

export const createSecondaryUser = (newUser, config) =>
  axios.post(url + "secondary-user/create/", newUser, config);

export const login = (body, config) =>
  axios.post(url + "signin/", body, config);

export const fetchUser = (id, config) => axios.get(url + `user/${id}/`, config);

export const fetchSecondaryUser = (id, config) =>
  axios.get(url + `secondary-user/${id}/`, config);

export const deleteSecondaryUser = (id, config) =>
  axios.delete(url + `secondary-user/${id}/delete/`, config);

export const putSecondaryName = (id, updatedName, config) =>
  axios.put(url + `secondary-user/${id}/rename/`, updatedName, config);

export const putSecondaryBday = (id, updatedBday, config) =>
  axios.put(url + `secondary-user/${id}/bday/`, updatedBday, config);

export const putSecondaryBio = (id, updatedBio, config) =>
  axios.put(url + `secondary-user/${id}/bio/`, updatedBio, config);

export const putSecondaryPrivacy = (id, bool, config) =>
  axios.put(url + `secondary-user/${id}/privacy/`, bool, config);

export const fetchSearch = (string, config) =>
  axios.get(url + `search/search=${string}/`, config);

// export const fetchQuestions = (config) =>
//   axios.get(url + "user/unanswered-questions", config);

// export const fetchSecondaryUserQuestions = (id, config) =>
//   axios.get(url + `secondary-user/${id}/unanswered-questions/`, config);
