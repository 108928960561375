import React from "react";
import {Link} from "react-router-dom";

import classes from "./Footer.module.scss";
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import {ArrowUpward} from "@material-ui/icons";
import CookieConsent from "react-cookie-consent";

function Copyright() {
    return (
        <Typography variant='body2' color='textSecondary' align='center'>
            {"Copyright © "}
            <Link color='inherit' to='/'>
                EveryPast
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
        </Typography>
    );
}

const Footer = () => {
    const handleClick = () => {
        window["scrollTo"]({top: 0, behavior: "smooth"});
    };
    return (
        <>
            <Toolbar className={classes.footer}>
                <IconButton
                    className={classes.up}
                    edge='start'
                    color='inherit'
                    aria-label='to top'
                    onClick={handleClick}
                >
                    <ArrowUpward/>
                </IconButton>
                <div className={classes.links}>
                    <Link to='/about'>About</Link>
                    <Link to='/contact'>Contact</Link>
                    <Link to='/privacypolicy'>Privacy</Link>
                    <Link to='/terms'>Terms</Link>
                    <Link to='/sitemap'>Site Map</Link>
                </div>
            </Toolbar>
            <div className={classes.copy}>
                <Copyright/>
            </div>
            <CookieConsent
                location="bottom"
                style={{background: "linear-gradient(to bottom, #e9ebed, #ffffff)", color: "black"}}
                buttonStyle={{background: "#4e9a9b", color: "white"}}>
                We will retain and evaluate information (1) on your recent visits to our website and (2) how you move
                around different sections of our website. This is for analytics purposes. We use this data to understand
                how people use our website, so that we can make it more intuitive.
            </CookieConsent>
        </>
    );
};

export default Footer;
