export default (answers = [], action) => {
  switch (action.type) {
    case "CREATE_ANSWER":
      // spread answers, add the current answer to the list
      return [...answers, action.payload];

    default:
      return answers;
  }
};
