import React from "react";

import LandingCard from "../components/landingCard/LandingCard";
import classes from "./LandingPage.module.scss";

const LANDING_INFO = [
  {
    id: 1,
    title: "Record Your Story",
    desc: "Record your own life story. By telling your own story, you get to influence how future generations remember you. You contribute to the recorded body of human knowledge.",
    btnText: "Tell your story",
  },
  {
    id: 2,
    title: "Record a Loved One's Story",
    desc: "Help your parent, grandparent, or loved one record their life story. Not everyone can easily access the internet or use computers. By helping someone record their life story, you help ensure that they are never forgotten.",
    btnText: "Keep their history",
  },
  {
    id: 3,
    title: "Read Others' Stories",
    desc: "As our repository of life stories continues to grow, we hope it will become an invaluable resource for people studying their family history, studying history in general, or seeking to understand what it means to be human.",
    btnText: "Discover",
  },
];

const LandingPage = () => {
  return (
    <main className={classes.main}>
      <div className={classes.container}>
        {/* <h1 className={classes.pageTitle}>Every memory has value</h1> */}
        {LANDING_INFO.map((card) => {
          return (
            <LandingCard
              id={card.id}
              title={card.title}
              desc={card.desc}
              btnText={card.btnText}
            />
          );
        })}
      </div>
    </main>
  );
};

export default LandingPage;
