import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import {makeStyles} from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";

import Box from "@material-ui/core/Box";
import styles from "./ProfilePage.module.scss";
import "./ProfilePageAppbar.scss";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";
import Modal from "@material-ui/core/Modal";
import TextField from "@material-ui/core/TextField";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import {store} from "../index";
import MuiAlert from "@material-ui/lab/Alert";
import {removeResponse} from "../actions/auth";

import QuestionAnswerBlock from "../components/questionAnswerBlock/QuestionAnswerBlock";
import SecondaryUserBlock from "../components/secondaryUserBlock/SecondaryUserBlock";

import {useDispatch, useSelector} from "react-redux";
import {showLoader, hideLoader} from "../actions/auth";

import {getUser} from "../actions/user";
import {
    updateName,
    updateBio,
    addSecondaryUser,
    updateBday,
} from "../actions/user";
import classes from "../components/questionBlock/QuestionBlock.module.scss";

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </div>
    );
}

function Alert(props) {
    return <MuiAlert elevation={6} variant='filled' {...props} />;
}

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

// backgroundColor: theme.palette.background.paper,
// STYLE FOR TABS
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    text: {
        color: "green",
    },
    indicatorColor: {
        color: "green",
        backgroundColor: "green",
        borderColor: "green",
    },
}));

// STYLE FOR MODAL
const useDyles = makeStyles((theme) => ({
    root: {
        "& .MuiTextField-root": {
            margin: theme.spacing(1),
            width: "90%",
        },
    },
    paper: {
        position: "absolute",
        width: "70%",
        maxWidth: "600px",
        backgroundColor: theme.palette.background.paper,
        border: "none",
        borderRadius: "15px",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

export default function SimpleTabs() {
    const classes = useStyles();
    const dyles = useDyles();
    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);
    const user = useSelector((state) => state.user);
    const loading = useSelector((state) => state.auth.compLoad);
    const [value, setValue] = React.useState(0);

    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = useState(getModalStyle);

    const [open, setOpen] = useState(false);
    const [openBioModal, setOpenBioModal] = useState(false);

    const [isSubmitted, setIsSubmitted] = useState(false);
    const [addUserClicked, setAddUserClicked] = useState(false);
    const [response, setResponse] = useState("");
    const [helperTextB, setHelperTextB] = useState("");

    const [name, setName] = useState({
        first_name: localStorage.getItem("first_name"),
        last_name: localStorage.getItem("last_name"),
    });

    const [sUsers, setsUsers] = useState(store.getState().user.secondary_users);

    const [secondaryName, setSecondaryName] = useState({
        first_name: "",
        last_name: "",
        bday: "",
    });

    const [isChecked, setIsChecked] = useState(false);
    const terms = <p>I have received consent to manage this user’s profile</p>;

    const [bio, setBio] = useState({
        bio: user.bio,
    });

    //   const formatBday =
    //     user.bday.substring(5, 7) +
    //     "/" +
    //     user.bday.substring(8, 10) +
    //     "/" +
    //     user.bday.substring(0, 4);

    //   console.log(formatBday);

    const [bday, setBday] = useState({
        bday: user.bday,
    });

    // Open Name Modal
    const handleOpen = () => {
        setOpen(true);
    };

    // Open Bio Modal
    const handleOpenBioModal = () => {
        setOpenBioModal(true);
    };

    // Close Both Modals
    const handleClose = () => {
        setOpen(false);
        setOpenBioModal(false);
        setIsSubmitted(false);
    };

    useEffect(() => {
        dispatch(removeResponse());
    }, []);

    useEffect(() => {
        setResponse(auth.response);
        dispatch(getUser(auth.id));
    }, [dispatch, auth.id, auth.response]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleSubmitName = (e) => {
        // once user submits, we want to send over a post request with all the data they typed in
        e.preventDefault();
        // console.table(name);
        dispatch(updateName(name));
        // console.log("sending bday..." + bday.bday);
        dispatch(updateBday(bday));
        setIsSubmitted(true);
    };

    const handleSubmitBio = (e) => {
        // once user submits, we want to send over a post request with all the data they typed in
        e.preventDefault();
        dispatch(updateBio(bio));
        setIsSubmitted(true);
    };

    var date = document.getElementById("date");

    function checkValue(str, max) {
        if (str.charAt(0) !== "0" || str == "00") {
            var num = parseInt(str);
            if (isNaN(num) || num <= 0 || num > max) num = 1;
            str =
                num > parseInt(max.toString().charAt(0)) && num.toString().length == 1
                    ? "0" + num
                    : num.toString();
        }
        return str;
    }

    const handleBlur = (e) => {
        e.target.type = "text";
        var input = e.target.value;
        var values = input.split("/").map(function (v, i) {
            return v.replace(/\D/g, "");
        });
        var output = "";

        if (values.length == 3) {
            var year =
                values[2].length !== 4
                    ? parseInt(values[2]) + 2000
                    : parseInt(values[2]);
            var month = parseInt(values[0]) - 1;
            var day = parseInt(values[1]);
            var d = new Date(year, month, day);
            // if (!isNaN(d)) {
            //   document.getElementById("result").innerText = d.toString();
            //   var dates = [d.getMonth() + 1, d.getDate(), d.getFullYear()];
            //   output = dates
            //     .map(function (v) {
            //       v = v.toString();
            //       return v.length == 1 ? "0" + v : v;
            //     })
            //     .join(" / ");
            // }
        }
        e.target.value = output;
    };

    const handleInput = (e) => {
        e.target.type = "text";
        var input = e.target.value;
        // console.log(input);
        if (/\D\/$/.test(input)) input = input.substr(0, input.length - 3);
        var values = input.split("/").map(function (v) {
            return v.replace(/\D/g, "");
        });
        if (values[0]) values[0] = checkValue(values[0], 12);
        if (values[1]) values[1] = checkValue(values[1], 31);
        var output = values.map(function (v, i) {
            return v.length == 2 && i < 2 ? v + " / " : v;
        });
        e.target.value = output.join("").substr(0, 14);
    };

    const handleSubmitSecondaryUser = (e) => {
        // once user submits, we want to send over a post request with all the data they typed in
        e.preventDefault();
        // if (secondaryName.bday.length < 14) {
        //   setHelperTextB("Format must be MM/DD/YYYY (eg. 10/04/1987)");
        //   setTimeout(function () {
        //     setHelperTextB("");
        //   }, 5000);
        //   return;
        // }
        dispatch(showLoader());
        // console.table(name);
        dispatch(addSecondaryUser(secondaryName));
        // dispatch(getUser(auth.id));
        setAddUserClicked(false);
        setSecondaryName({
            first_name: "",
            last_name: "",
        });
        setTimeout(function () {
            dispatch(hideLoader());
        }, 1000);
    };

    // Name Modal Body
    const body = (
        <div style={modalStyle} className={dyles.paper}>
            <p id='simple-modal-description' className={classes.modalQuestion}>
                {null}
            </p>
            <form className={dyles.root} noValidate autoComplete='off'>
                <TextField
                    id='first_name'
                    label='First Name'
                    // multiline
                    rows={1}
                    defaultValue={name.first_name}
                    variant='outlined'
                    // value={name.first_name}
                    onChange={(e) => setName({...name, first_name: e.target.value})}
                />
                <TextField
                    id='last_name'
                    label='Last Name'
                    // multiline
                    rows={1}
                    defaultValue={name.last_name}
                    variant='outlined'
                    // value={name.last_name}
                    onChange={(e) => setName({...name, last_name: e.target.value})}
                />
                <TextField
                    id='bday'
                    label='Date of Birth (MM/DD/YYYY)'
                    // multiline
                    rows={1}
                    // defaultValue={userbday}
                    variant='outlined'
                    defaultValue={bday.bday}
                    onInput={handleInput}
                    onBlur={handleBlur}
                    onChange={(e) => setBday({bday: e.target.value})}
                />
            </form>
            <button
                className={styles.submitButton}
                type='submit'
                onClick={handleSubmitName}
            >
                Submit
            </button>
            <button className={styles.cancelButton} onClick={handleClose}>
                Cancel
            </button>
        </div>
    );

    const bodyBio = (
        <div style={modalStyle} className={dyles.paper}>
            <form className={dyles.root} noValidate autoComplete='off'>
                <TextField
                    id='bio'
                    label='Biography'
                    multiline
                    rows={8}
                    defaultValue={user.bio}
                    variant='outlined'
                    // value={name.first_name}
                    onChange={(e) => setBio({bio: e.target.value})}
                />
            </form>
            <button
                className={styles.submitButton}
                type='submit'
                onClick={handleSubmitBio}
            >
                Submit
            </button>
            <button className={styles.cancelButton} onClick={handleClose}>
                Cancel
            </button>
            <p style={{fontSize: "10px"}}>
                All submissions must abide by the{" "}
                <a href='/terms' target='_blank'>
                    Terms and Conditions
                </a>
                , which you accepted.
            </p>
        </div>
    );

    const bodySubmitted = (
        <div style={modalStyle} className={dyles.paper}>
            <div className={styles.thanksContainer}>
                <h1>Successfully Updated</h1>
                <button className={styles.viewInProfile} onClick={handleClose}>
                    Close
                </button>
            </div>
        </div>
    );

    const questionSection = () => {
        if (!user.answers) {
            return null;
        } else {
            return (
                <>
                    {user.answers.map((answer, index) => {
                        return (
                            <QuestionAnswerBlock
                                key={index}
                                text={answer.answer}
                                match={index}
                                id={answer.id}
                            />
                        );
                    })}
                </>
            );
        }
    };

    const userSection = () => {
        // console.table(user.secondary_users);
        // console.log(user.secondary_users[0].id);
        if (!user.secondary_users) {
            {
                // console.log("NO SEC USERS");
            }

            return null;
        } else {
            {
                // console.log("YES SEC USERS");
            }

            return (
                <>
                    {user.secondary_users.map((secondaryUser, index) => {
                        return (
                            <SecondaryUserBlock
                                key={index}
                                first_name={secondaryUser.first_name}
                                last_name={secondaryUser.last_name}
                                bday={secondaryUser.bday}
                                id={secondaryUser.id}
                                loading={loading}
                                response={response}
                                setResponse={setResponse}
                            />
                        );
                    })}
                </>
            );
        }
    };
    // const test = !user.questions ? null : user.questions[0].question;
    const addUser = (
        <div className={styles.userPadding}>
            <form className={dyles.root} noValidate autoComplete='off'>
                <TextField
                    id='secondary_first_name'
                    label='First Name'
                    // multiline
                    rows={1}
                    defaultValue={secondaryName.first_name}
                    variant='outlined'
                    // value={name.first_name}
                    onChange={(e) =>
                        setSecondaryName({...secondaryName, first_name: e.target.value})
                    }
                />
                <TextField
                    id='secondary_last_name'
                    label='Last Name'
                    // multiline
                    rows={1}
                    defaultValue={secondaryName.last_name}
                    variant='outlined'
                    // value={name.last_name}
                    onChange={(e) =>
                        setSecondaryName({...secondaryName, last_name: e.target.value})
                    }
                />

                <TextField
                    FormHelperTextProps={{
                        className: classes.helperText,
                    }}
                    id={date}
                    style={{color: "black"}}
                    name='bday'
                    value={secondaryName.bday}
                    helperText={helperTextB}
                    variant='outlined'
                    required
                    fullWidth
                    label='Date of Birth (MM/DD/YYYY)'
                    onInput={handleInput}
                    onBlur={handleBlur}
                    onChange={(e) =>
                        setSecondaryName({...secondaryName, bday: e.target.value})
                    }
                />
            </form>
            <div>
                By clicking the button below to create a sub-profile for another
                individual, you affirm that you have obtained that individual's lawful
                consent to have their biographical information recorded on EveryPast.
                Please treat your interviewee respectfully. When you record their
                answers, avoid stereotypes, misrepresentations, and manipulations of
                their words.
            </div>

            <FormControlLabel
                control={
                    <Checkbox value='allowTerms' color='primary' checked={isChecked}/>
                }
                label={terms}
                onClick={() => setIsChecked(!isChecked)}
            />

            <div styles='display: block'>
                <Button
                    type='submit'
                    variant='contained'
                    color='primary'
                    className={styles.button}
                    onClick={handleSubmitSecondaryUser}
                    disabled={
                        !isChecked ||
                        secondaryName.first_name.length < 1 ||
                        secondaryName.last_name.length < 1 ||
                        secondaryName.bday.length !== 14
                    }
                >
                    Add Sub-Profile
                </Button>
            </div>
            {/* <button
        className={styles.button}
        type='submit'
        onClick={handleSubmitSecondaryUser}
        disabled={!isChecked}
      >
        Add Sub-Profile
      </button> */}
            <div style={{height: "10px"}}></div>
        </div>
    );

    return (
        <>
            <div className={styles.container}>
                <div className={styles.padding}>
                    <h2>
                        <span>Editing&nbsp;</span>
                        {user.first_name} <span>{user.last_name}'s&nbsp;</span>
                        <span>Profile</span>
                    </h2>
                    <Link to={`/profile/${auth.id}`}>
                        <button>
                            View Profile
                            <VisibilityIcon className={styles.eye}/>
                        </button>
                    </Link>
                    <Link style={{textDecoration: "none"}} to={`/home`}>
                        <button className={styles.questionButton}>
                            Answer More Questions
                        </button>
                    </Link>
                </div>
            </div>
            <div className={classes.root}>
                <AppBar position='static' style={{background: "#333"}}>
                    <Tabs
                        centered
                        value={value}
                        onChange={handleChange}
                        aria-label='simple tabs example'
                        indicatorColor='primary'
                    >
                        <Tab label='Edit Info' {...a11yProps(0)} />
                        <Tab label='Saved Answers' {...a11yProps(1)} />
                        <Tab label='Sub-Profiles' {...a11yProps(2)} />
                    </Tabs>
                </AppBar>
                <TabPanel value={value} index={0} className={styles.tabs}>
                    <div className={styles.containerInfo}>
                        <div className={styles.padding}>
                            <div className={styles.textBox}>
                                <div className={styles.textFlex}>
                                    <h3>First Name:</h3>
                                    <h2>{user.first_name}</h2>
                                </div>

                                <div className={styles.textFlex}>
                                    <h3>Last Name:</h3>
                                    <h2>{user.last_name}</h2>
                                </div>

                                <div className={styles.textFlex}>
                                    <h3>Birth Date:</h3>
                                    <h2>
                                        {user.bday.substring(5, 7)}/{user.bday.substring(8, 10)}/
                                        {user.bday.substring(0, 4)}
                                        {/* {user.bday} */}
                                    </h2>
                                </div>
                            </div>

                            <div className={styles.edit} onClick={handleOpen}>
                                EDIT <EditIcon className={styles.editIcon}/>
                            </div>
                        </div>
                    </div>

                    <div className={styles.containerInfo}>
                        <div className={styles.padding}>
                            <h3>Biography</h3>
                            <span>
                (Use this space to record any memories and stories you like,
                even if they are not a direct answer to one of our question
                cards.)
              </span>
                            <div style={{height: "10px"}}></div>
                            <div
                                style={{
                                    background: "#e9f2f7",
                                    padding: "20px",
                                    borderRadius: "20px",
                                }}
                            >
                                <div style={{whiteSpace: "pre-line"}}>{user.bio}</div>
                            </div>
                            <div style={{height: "35px"}}></div>
                            <div className={styles.edit} onClick={handleOpenBioModal}>
                                EDIT <EditIcon className={styles.editIcon}/>
                            </div>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel value={value} index={1} className={styles.tabs}>
                    {questionSection()}
                </TabPanel>
                <TabPanel value={value} index={2} className={styles.tabs}>
                    <div className={styles.containerOther}>
                        {/* SET THE RESPONSE */}
                        {response && (
                            <Alert
                                style={{marginBottom: "20px"}}
                                severity='success'
                                onClick={() => {
                                    setResponse(null);
                                    dispatch({type: "ADD_RESPONSE", payload: response});
                                }}
                            >
                                {auth.response || response}
                            </Alert>
                        )}
                        <div
                            className={styles.padding}
                            onClick={() => setAddUserClicked(!addUserClicked)}
                        >
                            <AddCircleIcon className={styles.addCircle}/>
                            <h3>Create Sub-Profile</h3>
                        </div>
                        {addUserClicked ? addUser : null}
                        {userSection()}
                    </div>
                </TabPanel>
            </div>

            <Modal
                open={open}
                onClose={handleClose}
                aria-describedby='simple-modal-description'
            >
                {isSubmitted ? bodySubmitted : body}
            </Modal>

            <Modal
                open={openBioModal}
                onClose={handleClose}
                aria-describedby='simple-modal-description'
            >
                {isSubmitted ? bodySubmitted : bodyBio}
            </Modal>
        </>
    );
}
