import React, { useState } from "react";
import { useHistory, Link, Redirect } from "react-router-dom";

import { fade, makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";

import InputBase from "@material-ui/core/InputBase";

import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";

import SearchIcon from "@material-ui/icons/Search";
import AccountCircle from "@material-ui/icons/AccountCircle";
import EditIcon from "@material-ui/icons/Edit";

import desktopLogo from "./logo.png";
import desktopLogoMobile from "./logo-mobile.png";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../actions/auth";
import { showLoader, hideLoader } from "../../actions/auth";
import { getSearchResults } from "../../actions/search";

import "./MainNavigation.module.scss";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },

  name: {
    fontSize: "12px",
    marginRight: "10px",
  },

  edit: {
    marginRight: theme.spacing(0.2),
    color: "black",
  },

  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "black !important",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

export default function MainNavigation() {
  const auth = useSelector((state) => state.auth);
  // const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const [query, setQuery] = useState("");

  const [width, setWidth] = React.useState(window.innerWidth);
  const breakpoint = 600;
  React.useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    // subscribe to window resize event "onComponentDidMount"
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      // unsubscribe "onComponentDestroy"
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  const imageUrl = width >= breakpoint ? desktopLogo : desktopLogoMobile;

  // const handleSearch = (e) => {
  //   e.preventDefault();
  // };

  let history = useHistory();

  const keyPress = (e) => {
    if (e.keyCode == 13 && query.length > 0) {
      e.preventDefault();
      showLoader();
      dispatch(getSearchResults(query));
      history.push("/search");
    }
  };

  const searchSection = () => {
    if (width >= breakpoint) {
      return (
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            placeholder='Search database…'
            onKeyDown={keyPress}
            onChange={(e) => setQuery(e.target.value)}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{ "aria-label": "search" }}
          />
        </div>
      );
    } else {
      return (
        <div className={classes.search}>
          <Link to='./search'>
            <IconButton
              edge='end'
              aria-label='answer questions'
              aria-controls='go-to-search-page'
              aria-haspopup='true'
              // onClick={handleProfileMenuOpen}
              color='inherit'
              className={classes.edit}
            >
              <SearchIcon />
            </IconButton>
          </Link>
        </div>
      );
    }
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(logout());
  };

  const menuId = "primary-search-account-menu";

  const renderLogoutMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <Link
        to={`/profile/${auth.id}`}
        style={{
          textDecoration: "none !important",
        }}
      >
        <MenuItem
          onClick={handleMenuClose}
          style={{
            color: "black",
            textDecoration: "none !important",
            fontStyle: "unset",
            fontFamily:
              "Montserrat, Segoe UI, Tahoma, Geneva, Verdana, sans-serif",
            listStyle: "none !important",
          }}
        >
          View Profile
        </MenuItem>
      </Link>
      <Link
        to='/profile'
        style={{
          textDecoration: "none !important",
        }}
      >
        <MenuItem
          onClick={handleMenuClose}
          style={{
            color: "black",
            textDecoration: "none !important",
            fontStyle: "unset",
            fontFamily:
              "Montserrat, Segoe UI, Tahoma, Geneva, Verdana, sans-serif",
            listStyle: "none !important",
          }}
        >
          Edit Profile
        </MenuItem>
      </Link>
      <Link to='/account'>
        <MenuItem
          onClick={handleMenuClose}
          style={{
            color: "black",
            textDecoration: "none !important",
            fontStyle: "unset",
            fontFamily:
              "Montserrat, Segoe UI, Tahoma, Geneva, Verdana, sans-serif",
            listStyle: "none !important",
          }}
        >
          My Account
        </MenuItem>
      </Link>
      <Link to='/'>
        <MenuItem
          onClick={handleLogout}
          style={{
            color: "black",
            textDecoration: "none !important",
            fontStyle: "unset",
            fontFamily:
              "Montserrat, Segoe UI, Tahoma, Geneva, Verdana, sans-serif",
            listStyle: "none !important",
          }}
        >
          Logout
        </MenuItem>
      </Link>
    </Menu>
  );

  const homeItem = (
    <Link to='/'>
      <MenuItem
        onClick={handleMenuClose}
        style={{
          color: "black",
          textDecoration: "none !important",
          fontStyle: "unset",
          fontFamily:
            "Montserrat, Segoe UI, Tahoma, Geneva, Verdana, sans-serif",
          listStyle: "none !important",
        }}
      >
        Home
      </MenuItem>
    </Link>
  );

  const signupItem = (
    <Link to='/register'>
      <MenuItem
        onClick={handleMenuClose}
        style={{
          color: "black",
          textDecoration: "none !important",
          fontStyle: "unset",
          fontFamily:
            "Montserrat, Segoe UI, Tahoma, Geneva, Verdana, sans-serif",
          listStyle: "none !important",
        }}
      >
        Sign Up
      </MenuItem>
    </Link>
  );

  const renderLoginMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {homeItem}
      <Link to='/login'>
        <MenuItem
          onClick={handleMenuClose}
          style={{
            color: "black",
            textDecoration: "none !important",
            fontStyle: "unset",
            fontFamily:
              "Montserrat, Segoe UI, Tahoma, Geneva, Verdana, sans-serif",
            listStyle: "none !important",
          }}
        >
          Login
        </MenuItem>
      </Link>
      {signupItem}
    </Menu>
  );

  const renderEditMenu = (
    <Link to='/home'>
      <IconButton
        edge='end'
        aria-label='answer questions'
        aria-controls={menuId}
        aria-haspopup='true'
        // onClick={handleProfileMenuOpen}
        color='inherit'
        className={classes.edit}
      >
        <EditIcon />
      </IconButton>
    </Link>
  );

  const renderFinalMenu = () => {
    if (auth.isAuthenticated) {
      return renderLogoutMenu;
    } else {
      return renderLoginMenu;
    }
  };

  const renderEdit = () => {
    if (auth.isAuthenticated) {
      return renderEditMenu;
    }
  };

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {/* <MenuItem>
        <IconButton aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="secondary">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton aria-label="show 11 new notifications" color="inherit">
          <Badge badgeContent={11} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem> */}
      <MenuItem onClick={handleProfileMenuOpen}>
        {/* <IconButton
          aria-label='account of current user'
          aria-controls='primary-search-account-menu'
          aria-haspopup='true'
          color='inherit'
        >
          <AccountCircle />
        </IconButton> */}
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <AppBar
        position='static'
        style={{ background: "#fff", color: "black", position: "fixed" }}
      >
        <Toolbar>
          {/* <IconButton
            edge='start'
            className={classes.menuButton}
            color='inherit'
            aria-label='open drawer'
          >
            <MenuIcon />
          </IconButton> */}
          {/* <AvatarIcon alt='Example Alt' src='./logo.webp' /> */}
          {/* <style scoped>{css}</style> */}

          <div
            style={{
              backgroundImage: `url(${imageUrl})`,
              // backgroundSize: "150px",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              width: "200px",
              padding: "25px 0",
              cursor: "pointer",
            }}
            onClick={() => history.push("/")}
          />

          {/* <img src={logo} alt='Everypast' width='150'></img> */}

          {/* <Typography className={classes.title} variant='h6' noWrap>
            EVERYpast
          </Typography> */}

          {searchSection()}

          <div className={classes.grow} />
          {auth.first_name ? (
            <div className={classes.name}>Welcome, {auth.first_name}!</div>
          ) : (
            ""
          )}
          {renderEdit()}

          <div className={classes.sectionDesktop}>
            {/* <IconButton aria-label='show 4 new mails' color='inherit'>
              <Badge badgeContent={4} color='secondary'>
                <MailIcon />
              </Badge>
            </IconButton>
            <IconButton aria-label='show 17 new notifications' color='inherit'>
              <Badge badgeContent={17} color='secondary'>
                <NotificationsIcon />
              </Badge>
            </IconButton> */}
            <IconButton
              edge='end'
              aria-label='account of current user'
              aria-controls={menuId}
              aria-haspopup='true'
              onClick={handleProfileMenuOpen}
              color='inherit'
            >
              <AccountCircle />
            </IconButton>
          </div>

          <div className={classes.sectionMobile}>
            {/* <IconButton
              aria-label='show more'
              aria-controls={mobileMenuId}
              aria-haspopup='true'
              onClick={handleMobileMenuOpen}
              color='inherit'
            >
              <MoreIcon />
            </IconButton> */}
            <IconButton
              edge='end'
              aria-label='account of current user'
              aria-controls={menuId}
              aria-haspopup='true'
              onClick={handleProfileMenuOpen}
              color='inherit'
            >
              <AccountCircle />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderFinalMenu()}
    </div>
  );
}
